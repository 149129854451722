import { useAPI, useQuery, useMutation, APIInput } from '@mybonus/ui';
import { useState } from 'react';

export function useFaqItemList() {
	const [deleteId, setDeleteId] = useState<number>();
	const [deletedIds, setDeletedIds] = useState<number[]>([]);
	const { api } = useAPI();

	const list = useQuery(
		'admin.faq.item.list',
		(args) => api.admin.faq.item.list.query(args),
		undefined,
		{ ttl: false },
	);

	const remove = useMutation<APIInput['admin']['faq']['item']['remove']>(
		(input) => api.admin.faq.item.remove.mutate(input),
	);

	const inactivate = useMutation<APIInput['admin']['faq']['item']['update']>(
		(input) => api.admin.faq.item.update.mutate(input),
		{
			onSuccess: () => list.load({ refetch: true, bypassCache: true }),
		},
	);

	function handleInactivate(id: number, active: boolean) {
		inactivate.mutate({ id, data: { active: !active } });
	}

	return {
		list,
		remove,
		inactivate,
		handleInactivate,
		deleteId,
		setDeleteId,
		deletedIds,
		setDeletedIds,
	};
}
