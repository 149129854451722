import { useAPI, useQuery } from '@mybonus/ui';
import { useState, useMemo, useCallback, useEffect } from 'react';

import type { TagSelectProps } from './TagSelect';

export function useTagSelect(props: TagSelectProps) {
	const { api } = useAPI();
	const [selectedTagId, setSelectedTagId] = useState<number | null>(
		props.value || null,
	);

	const list = useQuery(
		'admin.tag.list',
		() => api.admin.tag.list.query(),
		undefined,
	);

	const selectedTag = useMemo(
		() => (list.data || []).find((t) => t.id === selectedTagId),
		[selectedTagId, list.data],
	);

	const value = useMemo(
		() =>
			selectedTag
				? {
						id: String(selectedTag.id),
						label: selectedTag.name,
				  }
				: null,
		[selectedTag],
	);

	const onSelect = useCallback(
		(id: number | null) => {
			setSelectedTagId(id);
			props.onChange?.(id);
		},
		[props.onChange],
	);

	useEffect(() => {
		setSelectedTagId(props.value || null);
	}, [props.value]);

	return {
		list,
		value,
		onSelect,
	};
}
