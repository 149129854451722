import Pagination from '@mui/material/Pagination';
import type { PaginationInfo } from '@mybonus/public';

export type PaginationProps = {
	state?: PaginationInfo | undefined;
	setPage: (page: number) => void;
};

export function Paginate(props: PaginationProps) {
	return (
		<Pagination
			count={props.state?.pages || 1}
			page={props.state?.page || 1}
			onChange={(_, p) => props.setPage(p)}
			color="primary"
		/>
	);
}
