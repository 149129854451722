import { styled } from '../../theme';
import { ListStore } from '../ListStore';
import { Waiter } from '../Waiter';

export const List = styled(Waiter)``;

export const Item = styled(ListStore)<{ isFirst: boolean }>`
	border-top: ${({ isFirst, theme }) =>
		isFirst ? 'none' : `1px solid ${theme.color.lightGray.base}`};
	cursor: pointer;
`;
