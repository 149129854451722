import { Anchor } from '../../primitives';
import { styled } from '../../theme';
import { Center } from '../Center';
import { Paragraph as _Paragraph } from '../Paragraph';
import { Spinner as _Spinner } from '../Spinner';

export const Wrapper = styled(Center)`
	text-align: center;
	flex-direction: column;
	margin-top: ${({ theme }) => theme.space.md}px;
`;

export const Paragraph = styled(_Paragraph)``;

export const Spinner = styled(_Spinner)`
	margin: 20px;
`;

export const TryAgainAnchor = styled(Anchor)`
	padding-top: ${({ theme }) => theme.space.md}px;
`;
