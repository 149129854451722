import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export type StatusSnackbarProps = {
	open: boolean;
	onClose: () => void;
	text: string;
};

export function StatusSnackbar(props: StatusSnackbarProps) {
	return (
		<Snackbar
			open={props.open}
			autoHideDuration={6000}
			onClose={() => props.onClose()}
		>
			<Alert onClose={props.onClose} severity="success">
				{props.text}
			</Alert>
		</Snackbar>
	);
}
