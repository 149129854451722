import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import type { Duration } from '@mybonus/public';
import { useAPI, useMutation, APIInput } from '@mybonus/ui';
import { useState, useEffect } from 'react';

import {
	UpsertButton,
	RelativeDateTimeInput,
	Page,
	ErrorPopup,
} from '../../components';
import AudienceSelector from '../../components/audience/select';
import {
	AudienceSyncScheduleForm,
	AudienceSyncScheduleFormState,
	audienceSyncScheduleToAPIInput,
} from '../../components/audience/sync/schedule';

export function CashbackSharesCreateView() {
	const [cashbackShare, setCashbackShare] = useState(75);
	const [reason, setReason] = useState('');
	const [audienceId, setAudienceId] = useState<number>();
	const [duration, setDuration] = useState<Duration | undefined>();
	const [scheduleState, setScheduleState] =
		useState<AudienceSyncScheduleFormState>();

	const { api } = useAPI();

	const create = useMutation<
		APIInput['admin']['cashbackShare']['construct']['create']
	>((input) => api.admin.cashbackShare.construct.create.mutate(input));

	useEffect(() => setReason(''), [create.isSuccess]);

	const formIsValid =
		audienceId &&
		reason.length &&
		cashbackShare >= 50 &&
		cashbackShare <= 100 &&
		duration &&
		duration.value > 0 &&
		scheduleState;

	function onSubmit() {
		if (formIsValid) {
			create.mutate({
				reason,
				cashbackShare: cashbackShare,
				duration,
				schedule: audienceSyncScheduleToAPIInput(scheduleState),
				audienceId: audienceId,
			});
		}
	}

	return (
		<Page title="Create cashback share">
			<div>
				<TextField
					sx={{ mr: 2 }}
					required
					value={reason}
					label="Reason"
					placeholder="Good customers"
					size="small"
					onChange={(e) => setReason(e.target.value)}
				/>
				<TextField
					required
					type="number"
					value={cashbackShare}
					label="Share %"
					size="small"
					onChange={(e) => setCashbackShare(Number(e.target.value))}
					InputProps={{
						endAdornment: <InputAdornment position="end">%</InputAdornment>,
						inputProps: { min: 50, max: 100 },
					}}
				/>
			</div>

			<Box>
				<p>Active Duration</p>
				<RelativeDateTimeInput onChange={(v) => setDuration(v)} />
			</Box>

			<Box sx={{ maxWidth: '300px' }}>
				<AudienceSelector
					size="small"
					onChange={(id) => setAudienceId(id)}
					offerPreviewLink
				/>
			</Box>

			<AudienceSyncScheduleForm
				onChange={(state) => setScheduleState(state)}
				subject={{ singular: 'cashback share', plural: 'cashback shares' }}
			/>

			<UpsertButton
				disabled={!formIsValid}
				isLoading={create.isLoading}
				onClick={onSubmit}
			/>

			<Snackbar
				open={!!create.isSuccess}
				autoHideDuration={6000}
				onClose={create.reset}
			>
				<Alert onClose={create.reset} severity="success">
					Cashback shares created!
				</Alert>
			</Snackbar>

			<ErrorPopup error={create.error} onClose={create.reset} />
		</Page>
	);
}
