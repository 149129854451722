import Box from '@mui/material/Box';
import { Spinner, useAuthContext } from '@mybonus/ui';
import { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export type AdminRouteProps = {
	component: any;
	path?: string;
	roles?: string[];
};

export function AdminRoute(props: AdminRouteProps) {
	const { component: RouteComponent } = props;
	const auth = useAuthContext();
	const navigate = useNavigate();

	const isAuthenticated = useMemo(
		() => auth.account && auth.account.type === 'ADMIN',
		[auth],
	);

	useEffect(() => {
		if (!isAuthenticated && !auth.isLoading) {
			navigate('/auth/login');
		}
	}, [isAuthenticated, auth.isLoading]);

	if (isAuthenticated) {
		return <RouteComponent />;
	}

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
			}}
		>
			<Spinner />
		</Box>
	);
}
