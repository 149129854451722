import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';

export type PageTitleProps = {
	children?: ReactNode;
};

export function PageTitle(props: PageTitleProps) {
	return (
		<Box mb={3}>
			<Typography variant="h6">{props.children}</Typography>
		</Box>
	);
}
