import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export type ConfirmProps = {
	open: boolean;
	onConfirm: () => void;
	onClose: () => void;
	title: string;
	message: string;
	onConfirmText?: string;
	isLoading?: boolean;
};

export function Confirm(props: ConfirmProps) {
	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title"> {props.title} </DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{props.message}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="inherit">
					Close
				</Button>
				<LoadingButton
					onClick={props.onConfirm}
					autoFocus
					color="error"
					variant="outlined"
					loading={props.isLoading}
				>
					{props.onConfirmText || 'Go for it'}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
