import { Box } from '../../primitives';
import { styled } from '../../theme';

export type WaiterProps = {
	ready: boolean;
};

// TODO: We can display a loading spinner if the loading takes to much time

export const Waiter = styled(Box)<WaiterProps>`
	transition: opacity 0.2s ease-in-out;

	opacity: ${({ ready }) => (ready ? '1' : '0')};
`;
