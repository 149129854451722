import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import type { Query as AudienceQuery } from '@mybonus/public';
import { useAPI, useMutation, APIInput } from '@mybonus/ui';
import { useEffect, useState } from 'react';

import {
	Page,
	ErrorPopup,
	Switch,
	StatusSnackbar,
	UpsertButton,
} from '../../components';
import { Builder } from '../../components/audience/builder';
import { config } from '../../components/audience/builder/config';
import AudiencePreview from '../../components/audience/preview';

// TODO: Use this for modifying/previewing audiences as well
export function AudienceCreateView() {
	const initialQuery: AudienceQuery = {
		nodeType: 'GROUP',
		conjunction: 'AND',
		negated: false,
		children: [],
	};
	const [query, setQuery] = useState<AudienceQuery>(initialQuery);
	const [name, setName] = useState<string>('');
	const [showDebug, setShowDebug] = useState(false);

	const { api } = useAPI();
	const create = useMutation<APIInput['admin']['audience']['create']>((input) =>
		api.admin.audience.create.mutate(input),
	);

	function onSubmit() {
		create.mutate({ query: JSON.stringify(query), name });
	}

	useEffect(() => {
		if (name) {
			setName('');
		}
	}, [create.data]);

	return (
		<Page title="Create new audience">
			<TextField
				required
				value={name}
				label="Audience Name"
				placeholder="My Audience"
				size="small"
				onChange={(e) => setName(e.target.value)}
			/>

			<Builder
				config={config}
				query={query}
				onChange={(q) => {
					setQuery(q);
					console.log('Query changed!', q);
				}}
			/>

			<Switch
				on={showDebug}
				onChange={() => setShowDebug(!showDebug)}
				label="Show raw query"
				content={
					<Collapse in={showDebug}>
						<pre>{JSON.stringify(query, null, 2)})</pre>
					</Collapse>
				}
			/>

			<Box justifyContent="flex-end">
				<UpsertButton
					disabled={!query || !name.length}
					isLoading={create.isLoading}
					onClick={onSubmit}
				/>
			</Box>

			<StatusSnackbar
				open={!!create.isSuccess}
				onClose={create.reset}
				text="Audience created!"
			/>

			<ErrorPopup error={create.error} onClose={create.reset} />

			<AudiencePreview query={query} />
		</Page>
	);
}
