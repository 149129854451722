import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import type { LocalizedText, LocalizedTextInput } from '@mybonus/public';
import { useAPI, useQuery } from '@mybonus/ui';
import { ReactNode, useMemo } from 'react';

export type LocalizationEditorProps = {
	localizationMap?: LocalizedText | LocalizedTextInput;
	onChange: (localizationMap: LocalizedText | LocalizedTextInput) => void;
	renderer: (
		value: string,
		onChange: (value: string) => void,
		language: string,
	) => ReactNode;
};

export function LocalizationEditor(props: LocalizationEditorProps) {
	const { api } = useAPI();

	const languages = useQuery(
		'guest.language.list',
		(input) => api.guest.language.list.query(input),
		undefined,
	);

	const map = props.localizationMap || { sv: { text: '' } };

	const otherLanguages = useMemo(() => {
		const mapLanguages = Object.keys(map);
		return languages.data?.filter((l) => !mapLanguages.includes(l.code));
	}, [map, languages]);

	function getLanguageName(languageCode: string) {
		return languages.data?.find((l) => l.code === languageCode)?.name;
	}

	function addLanguage(languageCode: string) {
		props.onChange({ ...map, [languageCode]: { text: '' } });
	}

	function onTextChange(languageCode: string, text: string) {
		props.onChange({
			...map,
			[languageCode]: {
				...(languageCode in map ? map[languageCode as keyof typeof map] : {}),
				text,
			},
		});
	}

	return (
		<Box py={1}>
			{Object.keys(map).map((lang) => (
				<Box
					py={1}
					key={lang}
					sx={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{props.renderer(
						map[lang as keyof typeof map]!.text,
						(value: any) => {
							onTextChange(lang, value);
						},
						getLanguageName(lang) || '',
					)}
				</Box>
			))}

			{/* TODO: Other languages are cluttering admin panel and is not used/fully implemented yet
			{!!otherLanguages?.length && (
				<Autocomplete
					onChange={(e, value) => {
						if (value) {
							addLanguage(value.id);
						}
					}}
					size="small"
					options={otherLanguages.map((l) => ({ label: l.name, id: l.code }))}
					renderInput={(params) => (
						<TextField {...params} label="Add translation" />
					)}
				/>
			)}
			*/}
		</Box>
	);
}
