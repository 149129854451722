import type { DateFieldState } from '@mybonus/public';
import { FC, useState } from 'react';

import { DateTimeInput, RelativeDateTimeInput } from '../../../data';
import type { FieldConfig } from '../config';

export type DateFieldProps = {
	fieldConfig: FieldConfig;
	field: DateFieldState;
	onChange: (field: DateFieldState) => void;
	disabled?: boolean;
};

export const DateField: FC<DateFieldProps> = (props) => {
	const op = props.field.state.op;
	switch (op) {
		case 'UNSET':
		case 'EQ':
		case 'NEQ':
		case 'GT':
		case 'GTE':
		case 'LT':
		case 'LTE':
			return <DateAbsoluteField {...props} />;
		case 'IN_THE_LAST':
		case 'MORE_THAN':
			return <DateRelativeField {...props} />;
		case 'INSIDE':
		case 'OUTSIDE':
			return <DateRangeField {...props} />;
	}
};

export const DateRelativeField: FC<DateFieldProps> = (props) => {
	const { field } = props;
	const { state } = field;

	return (
		<RelativeDateTimeInput
			suffixText={state.op === 'MORE_THAN' ? 'Ago' : undefined}
			initialValue={state.value as any}
			disabled={props.disabled}
			onChange={(newDuration) => {
				props.onChange({
					...field,
					state: {
						...state,
						value: { ...newDuration },
					} as any,
				});
			}}
		/>
	);
};

export const DateAbsoluteField: FC<DateFieldProps> = (props) => {
	const { fieldConfig, field, onChange, disabled } = props;
	const { state } = field;
	const [value, setValue] = useState<Date | null>((state.value as any) || null);

	function onChangeValue(date: Date | null) {
		setValue(date);

		if (date) {
			onChange({
				...field,
				state: {
					...state,
					value: date,
				} as any,
			});
		}
	}

	return (
		<DateTimeInput
			onChange={onChangeValue}
			label={fieldConfig.label}
			disabled={disabled}
			clearable
			value={value}
		/>
	);
};

export const DateRangeField: FC<DateFieldProps> = () => {
	return <div>Not supported :(</div>;
};
