import { Wrapper } from './Spinner.parts';

export type SpinnerProps = {
	size?: 'sm' | 'md' | 'lg';
};

export function Spinner(props: SpinnerProps & { className?: string }) {
	return (
		<Wrapper viewBox="0 0 50 50" className={props.className}>
			<circle
				className="path"
				cx="25"
				cy="25"
				r="20"
				fill="none"
				strokeWidth="5"
			/>
		</Wrapper>
	);
}
