import { slugify } from '../utils/data/transform';

export const config = {
	minimumCashbackForWithdrawal: 100,
	defaultCurrencyCode: 'SEK',
	defaultLanguageCode: 'sv',
	defaultLocale: 'sv-SE',
	urls: {
		store: (opts: { webBaseUrl?: string; storeId: number; storeName: string }) =>
			`${opts.webBaseUrl || ''}/store/${opts.storeId}/${slugify(opts.storeName)}`,
		redirect: (opts: { webBaseUrl?: string; storeId: number; campaignId?: number }) =>
			`${opts.webBaseUrl || ''}/out/${opts.storeId}${
				opts.campaignId ? `?campaignId=${opts.campaignId}` : ''
			}`,
		invite: (webBaseUrl: string, code: string, storeId?: number): string =>
			`${webBaseUrl}/r/${code}${storeId ? `?store=${storeId}` : ''}`,
		// TODO: Update this to match new web
		passwordReset: (webBaseUrl: string, code: string): string => `${webBaseUrl}/l/${code}`,
		emailConfirmation: (webBaseUrl: string, code: string): string => `${webBaseUrl}/n/${code}`,
		defaultAvatarUrl: (cdnUrl: string): string => `${cdnUrl}/images/layout/profile_default.png`,
		unsubscribeTip: (webBaseUrl: string, email: string): string =>
			`${webBaseUrl}/unsubscribe?e=${email}`,
		login: (opts?: { webBaseUrl?: string; redirectBackToPath?: string }) =>
			`${opts?.webBaseUrl || ''}/login${
				opts?.redirectBackToPath ? `?redirect=${encodeURIComponent(opts.redirectBackToPath)}` : ''
			}`,
		claim: (webBaseUrl?: string) => `${webBaseUrl || ''}/account/claim`,
		terms: (webBaseUrl?: string) => `${webBaseUrl || ''}/terms`,
		privacyPolicy: (webBaseUrl?: string) => `${webBaseUrl || ''}/privacy`,
		cookiePolicy: (webBaseUrl?: string) => `${webBaseUrl || ''}/cookies`,
	},
	avatarSize: 256,
	claim: {
		trackEmail: (trackId: string): string => `${trackId}@track.mybonus.com`,
		minAge: '48h',
		maxAge: '60d',
	},
	company: {
		name: 'MyBonus AB',
		street: 'Fabriksstråket 32',
		zip: '433 76',
		city: 'Jonsered',
		countryCode: 'SE',
		orgNo: '556994-6832',
	},
	customerServiceEmail: 'kundservice@mybonus.com',
	defaultEmailFromAddress: {
		fullname: 'MyBonus',
		email: 'no-reply@mybonus.com',
	},
	identification: {
		leeway: '10m',
	},
	reward: {
		firstLoginOnPlatform: {
			rewardDuration: '7 days',
			cashbackShareReward: 100,
			platformAliases: ['EXT_CHROME'],
		},
	},
	recruitmentCodeLength: 6,
	passwordResetCodeValidPeriod: '24h',
	fulfillEmailChangeCodeValidDays: 2,
	baseCashbackShare: 50,
	storage: {
		keys: {
			recruitmentCode: 'recruitmentCode',
			consent: 'consent',
		},
	},
	app: {
		scheme: 'mybonus://',
		android: {
			playStoreUrl: 'https://play.google.com/store/apps/details?id=com.mybonus.app',
		},
		ios: {
			appStoreUrl: 'https://itunes.apple.com/us/app/mybonus/id1447368328',
		},
	},
	plugin: {
		chrome: {
			url: 'https://chrome.google.com/webstore/detail/mybonus-autob%C3%A4ring/cpkgocojmfpjfnbdhodjifclifdkomod',
		},
	},
	web: {
		defaultTitle: 'MyBonus.com',
	},
} as const;
