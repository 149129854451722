import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { UniversalIntent, snakeCaseToHuman } from '@mybonus/public';
import { useState, useEffect } from 'react';

import { StoreSelect } from '../StoreSelect';
import { Switch } from '../Switch';

const TARGETS = Array.from(UniversalIntent.options.keys()).map(String);

export type UniversalIntentEditorProps = {
	onChange: (intent: UniversalIntent | undefined, forceApp: boolean) => void;
	forceAppOption?: boolean;
};

export function UniversalIntentEditor(props: UniversalIntentEditorProps) {
	const [target, setTarget] = useState('START');
	const [targetId, setTargetId] = useState<number>();
	const [forceApp, setForceApp] = useState(false);

	useEffect(() => {
		try {
			const intent = UniversalIntent.parse({ target, id: targetId });
			props.onChange(intent, forceApp);
		} catch (err) {
			props.onChange(undefined, forceApp);
		}
	}, [target, targetId, forceApp]);

	return (
		<Box sx={{ maxWidth: '400px' }}>
			<Autocomplete
				sx={{ maxWidth: '400px', mb: 1 }}
				value={{ id: target, label: snakeCaseToHuman(target) }}
				isOptionEqualToValue={(opt, v) => opt.id === v.id}
				autoComplete
				autoSelect
				size="small"
				options={TARGETS.map((t) => ({
					id: t,
					label: snakeCaseToHuman(t),
				}))}
				renderInput={(params) => <TextField {...params} label="Target" />}
				onChange={(_e, value) => {
					value && setTarget(value.id);
				}}
			/>

			{(target === 'STORE' ||
				target === 'STORE_CAMPAIGNS' ||
				target === 'STORE_REDIRECT') && <StoreSelect onChange={setTargetId} />}

			{!!props.forceAppOption && (
				<Switch
					on={forceApp}
					onChange={() => setForceApp(!forceApp)}
					label="Force open in app or redirect to app store"
				/>
			)}
		</Box>
	);
}
