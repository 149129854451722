import TextField from '@mui/material/TextField';
import { UniversalIntent } from '@mybonus/public';
import { useMutation, useAPI, APIInput } from '@mybonus/ui';
import { useState, useEffect } from 'react';

import {
	Page,
	ErrorPopup,
	UpsertButton,
	Switch,
	UniversalIntentEditor,
	StatusSnackbar,
} from '../../components';
import AudienceSelect from '../../components/audience/select';
import {
	AudienceSyncScheduleForm,
	AudienceSyncScheduleFormState,
	audienceSyncScheduleToAPIInput,
} from '../../components/audience/sync/schedule';

export function NotificationCreateView() {
	const [name, setName] = useState('');
	const [message, setMessage] = useState('');
	const [audienceId, setAudienceId] = useState<number>();
	const [useIntent, setUseIntent] = useState(false);
	const [intent, setIntent] = useState<UniversalIntent>();
	const [scheduleState, setScheduleState] =
		useState<AudienceSyncScheduleFormState>();

	const { api } = useAPI();
	const create = useMutation<
		APIInput['admin']['message']['notification']['construct']['create']
	>((input) => api.admin.message.notification.construct.create.mutate(input));

	useEffect(() => {
		setName('');
		setMessage('');
		setIntent(undefined);
		setUseIntent(false);
		setScheduleState(undefined);
	}, [create.isSuccess]);

	const formIsValid =
		audienceId &&
		name.length &&
		message.length &&
		((useIntent && intent) || !useIntent) &&
		scheduleState;

	function onSubmit() {
		if (formIsValid) {
			create.mutate({
				name,
				message,
				schedule: audienceSyncScheduleToAPIInput(scheduleState),
				audienceId: audienceId,
				intent: intent
					? UniversalIntent.parse({
							target: intent.target,
							id: 'id' in intent ? intent.id : undefined,
					  })
					: undefined,
			});
		}
	}

	return (
		<Page title="Send Notifications" width="contained">
			<AudienceSelect
				size="small"
				onChange={(id) => setAudienceId(id)}
				offerPreviewLink
			/>

			<TextField
				sx={{ minWidth: '400px' }}
				required
				value={name}
				label="Name (internal)"
				placeholder="v44 CDON push"
				size="small"
				onChange={(e) => setName(e.target.value)}
			/>

			<TextField
				multiline
				minRows={3}
				sx={{ minWidth: '400px' }}
				required
				value={message}
				label="Message"
				placeholder="Message to users..."
				size="small"
				onChange={(e) => setMessage(e.target.value)}
			/>

			<Switch
				on={useIntent}
				onChange={() => {
					setUseIntent(!useIntent);
					setIntent(undefined);
				}}
				label="Use intent"
			/>

			{useIntent && <UniversalIntentEditor onChange={(i) => setIntent(i)} />}

			<AudienceSyncScheduleForm
				onChange={(state) => setScheduleState(state)}
				subject={{ singular: 'message', plural: 'messages' }}
			/>

			<UpsertButton
				disabled={!formIsValid}
				isLoading={create.isLoading}
				onClick={onSubmit}
				text="Send"
			/>

			<StatusSnackbar
				open={!!create.isSuccess}
				onClose={create.reset}
				text="Notification scheduled!"
			/>

			<ErrorPopup error={create.error} onClose={create.reset} />
		</Page>
	);
}
