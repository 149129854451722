import { Outlet } from 'react-router-dom';

import { Menu } from '../Menu';
import { Wrapper } from './AppShell.parts';

export function AppShell() {
	return (
		<Menu>
			<Wrapper>
				<Outlet />
			</Wrapper>
		</Menu>
	);
}
