import { ErrorMessage } from '@mybonus/ui';

import {
	Page,
	ErrorPopup,
	CmsContentEditor,
	UpsertButton,
	LocalizationEditor,
	StatusSnackbar,
} from '../../components';
import { useCmsContentUpdate } from './StaticContentUpdateView.hooks';

export function StaticContentUpdateView() {
	const { notFound, form, isLoading, update, handleReset, error, params } =
		useCmsContentUpdate();

	return (
		<Page title={`Update CMS content for ${params.code}`} width="contained">
			{notFound && (
				<ErrorMessage
					error={`CMS content with code ${params.code} could not be found.`}
				/>
			)}

			<LocalizationEditor
				localizationMap={form.data.content}
				onChange={(value) => form.handleChange('content')(value)}
				renderer={(value, onChange) => (
					<CmsContentEditor value={value} onChange={onChange} />
				)}
			/>

			<StatusSnackbar
				open={!!update.isSuccess}
				onClose={handleReset}
				text="Content updated!"
			/>

			<UpsertButton
				disabled={isLoading}
				isLoading={isLoading}
				onClick={form.handleSubmit}
				mode="update"
			/>

			<ErrorPopup error={error} onClose={handleReset} />
		</Page>
	);
}
