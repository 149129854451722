import { RemoteImage, Text, Box } from '../../primitives';
import { styled } from '../../theme';
import { Column } from '../Column';
import { Row } from '../Row';

export const Item = styled(Row).attrs((props) => ({
	...props,
}))``;

export const LogoWrapper = styled(Box)`
	flex: 0 1 40%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${({ theme }) => theme.space.md}px;
`;
export const Logo = styled(RemoteImage)`
	max-width: 100%;
	max-height: 50px;
`;

export const Info = styled(Column)`
	flex: 1 1 50%;
	padding: ${({ theme }) => theme.space.md}px 0
		${({ theme }) => theme.space.md}px ${({ theme }) => theme.space.lg}px;
`;

export const Name = styled(Text)`
	margin-bottom: ${({ theme }) => theme.space.sm}px;
`;

export const Indicators = styled(Row)`
	flex: 1 1 10%;
`;
