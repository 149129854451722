import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import MuiLink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';

import {
	Page,
	ErrorPopup,
	Confirm,
	ActivateIcon,
	DeleteIcon,
} from '../../components';
import { useFaqGroupList } from './FaqGroupListView.hooks';

export function FaqGroupListView() {
	const {
		list,
		remove,
		deleteId,
		setDeleteId,
		deletedIds,
		setDeletedIds,
		handleInactivate,
	} = useFaqGroupList();

	return (
		<Page title="FAQ Groups">
			<Link to="/app/cms/faq/groups/create">
				<Button variant="contained">New Group</Button>
			</Link>
			{list.isLoading && <LinearProgress />}

			<ErrorPopup error={list.error} onRetry={list.load} onClose={list.reset} />

			<Confirm
				open={!!deleteId || remove.isLoading}
				onConfirm={() => {
					remove.mutate({ id: Number(deleteId) });
					setDeleteId(undefined);
					setDeletedIds([...deletedIds, Number(deleteId)]);
				}}
				onClose={() => setDeleteId(undefined)}
				title="Delete group?"
				message="Are you sure you want to delete this group?"
				isLoading={remove.isLoading}
			/>

			<ErrorPopup error={remove.error} onClose={remove.reset} />

			{list && (
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }}>
						<TableHead>
							<TableRow>
								<TableCell>ID</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Prio</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{list?.data?.map((row) => (
								<TableRow
									key={row.id}
									sx={{
										'&:last-child td, &:last-child th': { border: 0 },
										...(deletedIds.indexOf(row.id) !== -1 || !row.active
											? { opacity: 0.3 }
											: {}),
									}}
								>
									<TableCell component="th" scope="row">
										{row.id}
									</TableCell>
									<TableCell>
										<MuiLink
											component={Link}
											to={`/app/cms/faq/groups/${row.id}`}
										>
											{row.name}
										</MuiLink>
									</TableCell>
									<TableCell>{row.prio}</TableCell>
									<TableCell>
										<ActivateIcon
											isActive={!!row.active}
											onClick={() => handleInactivate(row.id, !!row.active)}
										/>
										<DeleteIcon onClick={() => setDeleteId(row.id)} />
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Page>
	);
}
