import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { useAPI, useQuery } from '@mybonus/ui';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Page, ErrorPopup, DateTime } from '../../components';
import { Builder, config } from '../../components/audience/builder';
import AudiencePreviewTable from '../../components/audience/preview/table';

export function AudienceView() {
	const params = useParams();
	const { api } = useAPI();

	const input = useMemo(() => ({ id: Number(params.id) }), [params.id]);
	const single = useQuery(
		'admin.audience.single',
		(args) => api.admin.audience.single.query(args),
		input,
	);

	return (
		<Page title={single.data?.name}>
			{single.isLoading && <LinearProgress />}
			<ErrorPopup
				error={single.error}
				onRetry={single.load}
				onClose={single.reset}
			/>

			{single.data && (
				<>
					<Typography>
						Created <DateTime date={single.data.created_at} />
					</Typography>
					<Typography variant="subtitle2">ID # {single.data.id}</Typography>
					<Builder
						config={config}
						query={JSON.parse(single.data.query)}
						disabled={true}
					/>
					<AudiencePreviewTable
						preview={single.data.preview}
						noMatchesMsg={'Audience have no participants.'}
						targetedPeopleMsg={(count) => `${count} participants`}
					/>
				</>
			)}
		</Page>
	);
}
