import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import MuiLink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAPI, useQuery, usePagination, Row } from '@mybonus/ui';
import { Link } from 'react-router-dom';

import { Page, ErrorPopup, Paginate, DateTime } from '../../components';
import { formatDuration } from '../../helpers/time';

export function CashbackShareListView() {
	const { api } = useAPI();
	const { setPage, pagination: input } = usePagination();

	const list = useQuery(
		'admin.cashbackShare.construct.list',
		(args) => api.admin.cashbackShare.construct.list.query(args),
		input,
	);

	return (
		<Page title="Cashback Shares">
			<Row apart>
				<Link to="/app/marketing/cashback-shares/create">
					<Button variant="contained">New cashback share</Button>
				</Link>

				<Paginate state={list.data?.pagination} setPage={setPage} />
			</Row>
			{list.isLoading && <LinearProgress />}

			<ErrorPopup error={list.error} onRetry={list.load} onClose={list.reset} />

			{list && (
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }}>
						<TableHead>
							<TableRow>
								<TableCell>ID</TableCell>
								<TableCell>Reason</TableCell>
								<TableCell>Share %</TableCell>
								<TableCell>Duration</TableCell>
								<TableCell>Created</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{list.data?.items.map((row) => (
								<TableRow
									key={row.id}
									sx={{
										'& td': { borderBottom: 'unset' },
									}}
								>
									<TableCell scope="row">{row.id}</TableCell>
									<TableCell>
										<MuiLink
											component={Link}
											to={`/app/marketing/cashback-shares/list/${row.id}`}
										>
											{row.reason}
										</MuiLink>
									</TableCell>
									<TableCell>{row.cashback_share}%</TableCell>
									<TableCell>
										{formatDuration({
											unit: row.duration_unit,
											value: row.duration_value,
										})}
									</TableCell>
									<TableCell>
										<DateTime date={row.created_at} />
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<Grid container direction="row" justifyContent="flex-end">
				<Paginate state={list.data?.pagination} setPage={setPage} />
			</Grid>
		</Page>
	);
}
