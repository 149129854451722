import { LanguageCode, mustacheReplace } from '@mybonus/public';
import { useMemo } from 'react';
import type { Options as RehypeReactOptions } from 'rehype-react';

import { useCmsContent, createParser } from './CmsContent.hooks';
import {
	DivWithInjectedStyle,
	CodeWrapper,
	MarkdownWrapper,
} from './CmsContent.parts';

export type CmsContentProps = {
	replacements?: Record<string, string>;
	components?: RehypeReactOptions['components'];
} & (
	| { html: string }
	| { code: string; languageCode?: LanguageCode }
	| { markdown: string }
);

export function CmsContent(props: CmsContentProps & { className?: string }) {
	const parser = useMemo(
		() => createParser(props.components),
		[props.components],
	);

	if ('html' in props) {
		const content = props.replacements
			? mustacheReplace(props.html, props.replacements)
			: props.html;
		return (
			<DivWithInjectedStyle
				dangerouslySetInnerHTML={{ __html: content }}
				className={props.className}
			/>
		);
	}

	if ('markdown' in props) {
		const content = useMemo(
			() =>
				parser.processSync(
					props.replacements
						? mustacheReplace(props.markdown, props.replacements)
						: props.markdown,
				).result,
			[props.markdown, props.replacements],
		);

		return (
			<MarkdownWrapper className={props.className}>{content}</MarkdownWrapper>
		);
	} else if ('code' in props) {
		const cms = useCmsContent(props.code, props.languageCode);

		const content = useMemo(
			() =>
				cms.data
					? parser.processSync(
							props.replacements
								? mustacheReplace(cms.data, props.replacements)
								: cms.data,
					  ).result
					: undefined,
			[cms.data, props.replacements],
		);

		return (
			<CodeWrapper ready={!cms.isLoading} className={props.className}>
				{content}
			</CodeWrapper>
		);
	}

	return <></>;
}
