import { TRPCClientError } from '@trpc/client';
import type { AnyRouter } from '@trpc/server';

import { Errors, errorMessages } from '../err';

export function isTRPCClientError(cause: unknown): cause is TRPCClientError<AnyRouter> {
	return cause instanceof TRPCClientError;
}

export function isServerError(cause: unknown, error: keyof typeof Errors | 'any' = 'any'): boolean {
	if (isTRPCClientError(cause)) {
		if (error === 'any' || Errors[error].name === cause.data.name) {
			return true;
		}
	}

	return false;
}

export function serverErrorToHuman(cause: unknown): string {
	if (!isTRPCClientError(cause)) {
		return 'Ett oväntat fel inträffade.';
	}

	return cause.data.message || errorMessages.default;
}

export function serverErrorToDebug(cause: unknown): string {
	if (isTRPCClientError(cause)) {
		if (cause.data) {
			return JSON.stringify(cause, null, 2);
		} else if (cause.stack) {
			return cause.stack;
		} else {
			return String(cause);
		}
	} else if (cause instanceof Error && cause.stack) {
		return cause.stack;
	} else {
		return String(cause);
	}
}
