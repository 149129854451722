import type { ReactNode } from 'react';

import { $Form } from './Form.parts';

export type FormProps = {
	onSubmit: () => void;
	children?: ReactNode;
};

export function Form(props: FormProps & { className?: string }) {
	return (
		<$Form
			className={props.className}
			onSubmit={(e) => {
				e.preventDefault();
				props.onSubmit();
			}}
		>
			{props.children}
		</$Form>
	);
}
