import { config } from '../config';
import type * as Errors from './exposed';

export type ErrorMessageMap = Partial<Record<keyof typeof Errors, string>> & { default: string };

const supportTxt = 'Vänligen kontakta kundtjänst om detta uppstår igen';

export const errorMessages: ErrorMessageMap = {
	default: `Ett oväntat fel inträffade`,
	AccountNotFoundError: `Kontot kunde inte hittas`,
	AffiliateCampaignInactiveError: `Affiliate-kampanjen var inaktiv`,
	AffiliateCampaignNotFoundError: `Affiliate-kampanjen kunde inte hittas`,
	AlreadyAnonymizedError: `Användaren är redan anonymiserad`,
	AudienceNameExistsError: `Det finns redan en audience med samma namn`,
	BankIDOngoingOrderError: `Det är redan en BankID process igång`,
	BankIDPollError: `Kunde inte kontakta BankID efter flera försök`,
	ClaimTimeExpiredError: `Kan inte efterregistrera köp äldre än ${config.claim.maxAge}`,
	ClaimTimeNotActiveError: `Köpet måste vara äldre än ${config.claim.minAge} innan du kan efterregistrera ditt köp`,
	GrantAccessLockedError: `Flera anrop för att förnya sessionen gjordes. ${supportTxt}`,
	IdentificationNotFoundError: `Kontot är inte identifierat`,
	IdentificationSSNMismatchError: `Kontots personnummer stämde inte överens med det vi fick från identifieringen`,
	InsufficientCashbackSumForWithdrawalError: `Du har inte uppnått uttagsgränsen ännu`,
	InvalidCredentialsError: `Inloggningsuppgifterna var felaktiga`,
	InvalidMFATokenError: `MFA-koden var ogiltig`,
	InvalidOrgNrError: `Organisationsnumret var ogiltigt`,
	InvalidPhoneNumberError: `Telefonnumret var inte giltigt`,
	InvalidPushTokenError: `Koden för push notiser var ogiltig`,
	InvalidRefreshTokenError: `Inloggningens sessionsnyckel var ogiltig`,
	InvalidResetPasswordToken: `Återställningskoden var ogiltig`,
	InvalidSSNError: `Personnumret var ogiltigt`,
	InvalidStoreGiftcardVendorOfferError: `Presentkortserbjudandet är inte längre giltigt`,
	InvalidURLError: `Ogiltig URL`,
	OutdatedClientError: `Då måste uppdatera din enhet till senaste versionen för att fortsätta`,
	RecruiterNotFoundError: `Rekryteraren kunde inte hittas`,
	RefreshTokenNotFoundError: `Inloggningens sessionsnyckel kunde inte hittas`,
	SSNNotUniqueError: `Det fanns redan en användare med samma personnummer`,
	StoreClickNotFoundError: `Det finns inget registrerat klick vid det angivna datumet`,
	StoreGiftcardVendorOfferLockedError: `Presentkortserbjudandet är låst och kan inte ändras`,
	TimeoutError: `Förfrågan tog för lång tid`,
	TrackTicketExistsError: `Det finns redan en efterregistrering med samma ordernummer på butiken`,
	UnauthorizedError: `Du har inte behörighet`,
	UnauthenticatedError: `Du är inte längre inloggad`,
	UserAccountNotCompleteError: `Din profil är inte komplett ifylld`,
	UserAlreadyExistsError: `En användare med samma email finns redan registrerad`,
	UserNotFoundError: `Användaren kunde inte hittas`,
	UserTemporaryEmailNotFoundError: `Verifieringskoden var ogiltig`,
	VendorAuthenticationError: `Ett fel inträffade vid inlogging via tredjepart`,
	VendorProfileIncompleteError: `Profilen från inloggning med tredjepart saknade nödvändig information`,
	ShortCodeNotFoundError: `Det gick inte att hitta en användare med koden/länken`,
	NetworkError: `Problem att ansluta till servern`,
};
