import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import {
	isServerError,
	serverErrorToDebug,
	serverErrorToHuman,
} from '@mybonus/public';
import type { APIError } from '@mybonus/ui';
import { useState } from 'react';

export interface ErrorPopupProps {
	error: null | undefined | Error | APIError;
	onClose: () => void;
	onRetry?: () => void;
}

export function ErrorPopup(props: ErrorPopupProps) {
	const [showDebug, setShowDebug] = useState(false);

	return (
		<Dialog
			open={!!props.error}
			onClose={props.onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Woops, någonting gick fel!
			</DialogTitle>
			<DialogContent>
				<Box sx={{ textAlign: 'center' }}>
					<Typography variant="subtitle2">
						{props.error
							? isServerError(props?.error)
								? serverErrorToHuman(props?.error)
								: `${props?.error.message}`
							: null}
					</Typography>

					<Box my={4} sx={{ minHeight: '100px' }}>
						<FormControlLabel
							control={
								<Checkbox
									checked={showDebug}
									onChange={() => setShowDebug(!showDebug)}
									size="small"
								/>
							}
							label={showDebug ? 'Hide debug' : 'Show debug'}
						/>
						<Collapse in={showDebug}>
							<pre style={{ textAlign: 'left' }}>
								{serverErrorToDebug(props.error)}
							</pre>
						</Collapse>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				{props.onRetry && <Button onClick={props.onRetry}>Retry</Button>}
				<Button
					autoFocus
					onClick={props.onClose}
					color="error"
					variant="outlined"
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}
