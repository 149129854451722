import { useAPI, useQuery } from '@mybonus/ui';
import { useState, useMemo, useCallback, useEffect } from 'react';

import type { HeroSelectProps } from './HeroSelect';

export function useHeroSelect(props: HeroSelectProps) {
	const { api } = useAPI();
	const [selectedHeroId, setSelectedHeroId] = useState<number | null>(
		props.value || null,
	);

	const list = useQuery(
		'admin.hero.list',
		() => api.admin.hero.list.query(),
		undefined,
	);

	const selectedHero = useMemo(
		() => (list.data || []).find((t) => t.id === selectedHeroId),
		[selectedHeroId, list.data],
	);

	const value = useMemo(
		() =>
			selectedHero
				? {
						id: String(selectedHero.id),
						label: selectedHero.name,
				  }
				: null,
		[selectedHero],
	);

	const onSelect = useCallback(
		(id: number | null) => {
			setSelectedHeroId(id);
			props.onChange?.(id);
		},
		[props.onChange],
	);

	useEffect(() => {
		setSelectedHeroId(props.value || null);
	}, [props.value]);

	return {
		list,
		value,
		onSelect,
	};
}
