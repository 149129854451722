import ArrowIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';

import { DateTimeInput } from '../DateTimeInput';

export type DateTimeRangeInputProps = {
	label?: string;
	from: Date;
	to: Date;
	onChange: (from: Date, to: Date) => void;
};

export function DateTimeRangeInput(props: DateTimeRangeInputProps) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<DateTimeInput
				value={props.from}
				label={props.label ? `${props.label} from` : 'From'}
				onChange={(v) => {
					console.log('from changed');
					v && props.onChange(v, props.to);
				}}
			/>
			<Box mx={2}>
				<ArrowIcon
					fontSize="medium"
					sx={{ color: '#555', paddingTop: '4px' }}
				/>
			</Box>
			<DateTimeInput
				value={props.to}
				label={props.label ? `${props.label} to` : 'To'}
				minDateTime={props.from}
				onChange={(v) => {
					console.log('to changed');
					v && props.onChange(props.from, v);
				}}
			/>
		</Box>
	);
}
