import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { UniversalIntent, snakeCaseToHuman } from '@mybonus/public';
import { useQuery, useAPI } from '@mybonus/ui';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Page, ErrorPopup, DateTime } from '../../components';

export function NotificationView() {
	const params = useParams();
	const { api } = useAPI();

	const input = useMemo(
		() => ({
			id: Number(params.id),
		}),
		[params.id],
	);

	const single = useQuery(
		'admin.message.notification.construct.single',
		(input) => api.admin.message.notification.construct.single.query(input),
		input,
	);

	return (
		<Page title={single.data?.name}>
			{single.isLoading && <LinearProgress />}
			<ErrorPopup
				error={single.error}
				onRetry={single.load}
				onClose={single.reset}
			/>

			{single.data && (
				<>
					<Typography variant="subtitle2">
						ID #{single.data.id}, Created{' '}
						<DateTime date={single.data.created_at} />
					</Typography>
					<Typography variant="subtitle2">Message</Typography>
					<pre style={{ whiteSpace: 'normal' }}>{single.data.message}</pre>
					{single.data.intent_target && (
						<NotificationIntent
							{...UniversalIntent.parse({
								target: single.data.intent_target,
								id: single.data.intent_target_id,
							})}
						/>
					)}
				</>
			)}
		</Page>
	);
}

// TODO: This component can be reusable, now when NotificationIntent -> UniversalIntent
function NotificationIntent(intent: UniversalIntent) {
	return (
		<Typography>
			Message targets <b>{snakeCaseToHuman(intent.target).toLowerCase()}</b>
			{'id' in intent && (
				<>
					{' '}
					with id <b>{intent.id}</b>
				</>
			)}
		</Typography>
	);
}
