import { Text } from '../../primitives';
import { styled, css } from '../../theme';
import { NotificationList } from '../NotificationList';
import { Popdown } from '../Popdown';
import { Waiter } from '../Waiter';

export const CountCircle = styled(Waiter)<{ unreadCount?: number }>`
	position: absolute;
	width: 22px;
	height: 22px;
	background-color: #fff;
	border-radius: 1000px;
	display: flex;
	justify-content: center;
	align-items: center;

	${({ unreadCount }) =>
		unreadCount && unreadCount > 99
			? css`
					width: 22px;
					height: 22px;
					top: 8px;
					right: 8px;
			  `
			: css`
					width: 18px;
					height: 18px;
					top: 11px;
					right: 11px;
			  `}
`;

export const CountText = styled(Text).attrs((props) => ({
	size: 'sm',
	color: props.theme.color.green.base,
	...props,
}))``;

export const $Popdown = styled(Popdown)`
	min-width: 440px;
`;

export const $NotificationList = styled(NotificationList)`
	max-height: 500px;
	overflow-y: scroll;
`;
