import { Typography, Paper, Link as MuiLink } from '@mui/material';
import type { UniversalIntent } from '@mybonus/public';
import { useState } from 'react';

import {
	Page,
	UniversalLinkPreview,
	UniversalIntentEditor,
} from '../../components';
import env from '../../env';

export function UniversalLinkGeneratorView() {
	const [intent, setIntent] = useState<UniversalIntent>();
	const [forceApp, setForceApp] = useState(false);

	const taggedCampaignUrl = `${env.V1_BASE_URL}/campaigns/tags/<tag>`;

	return (
		<Page title="Generate Universal Links">
			<Paper sx={{ p: 4 }}>
				<UniversalIntentEditor
					onChange={(i, a) => {
						setIntent(i);
						setForceApp(a);
					}}
					forceAppOption
				/>

				{!!intent && (
					<UniversalLinkPreview intent={intent} forceApp={forceApp} />
				)}
			</Paper>

			<Paper sx={{ p: 4 }}>
				<Typography variant="subtitle2">
					Link to tagged campaigns (web 1.0)
				</Typography>
				<MuiLink href={taggedCampaignUrl} target="_blank" sx={{ mt: 4 }}>
					<Typography>{taggedCampaignUrl}</Typography>
				</MuiLink>
			</Paper>
		</Page>
	);
}
