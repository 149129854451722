import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { ErrorPopup } from '../../layout';
import { useTagSelect } from './TagSelect.hooks';

export type TagSelectProps = {
	onChange?: (id: number | null) => void;
	value?: number;
	size?: 'small' | 'medium';
	error?: string | boolean;
};

export function TagSelect(props: TagSelectProps) {
	const { list, value, onSelect } = useTagSelect(props);

	return (
		<Box>
			<ErrorPopup error={list.error} onClose={list.reset} onRetry={list.load} />
			<Autocomplete
				isOptionEqualToValue={(opt, v) => opt.id === v.id}
				sx={{ minWidth: '260px' }}
				value={value}
				autoComplete
				loading={list.isLoading}
				autoSelect
				size={props.size || 'small'}
				options={(list.data || []).map((s) => ({
					id: String(s.id),
					label: s.name,
				}))}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Tag"
						error={!!props.error}
						helperText={
							typeof props.error === 'string' ? props.error : undefined
						}
					/>
				)}
				onChange={(_e, value) => {
					onSelect(value ? Number(value.id) : null);
				}}
			/>
		</Box>
	);
}
