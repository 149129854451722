import type { tRPCRouter } from '@mybonus/api/src/controllers/trpc';
import { createLocalStorageAuthStorage, TRPCClient } from '@mybonus/public';
import { createTRPCProxyClient } from '@trpc/client';

import env from '../env';

export const client = new TRPCClient({
	url: `${env.API_BASE_URL}/trpc`,
	platform: {
		alias: env.APP_PLATFORM_ALIAS,
		version: env.APP_VERSION,
	},
	authStorage: createLocalStorageAuthStorage(),
	accountType: 'ADMIN',
});

export const raw = createTRPCProxyClient<tRPCRouter>(client.createConfig());
