import TextField from '@mui/material/TextField';
import { ErrorMessage } from '@mybonus/ui';

import {
	Page,
	ErrorPopup,
	Switch,
	StatusSnackbar,
	LocalizationEditor,
	UpsertButton,
} from '../../components';
import { useFaqGroupUpsert } from './FaqGroupUpsertView.hooks';

export function FaqGroupUpsertView() {
	const {
		notFound,
		params,
		single,
		mode,
		form,
		isLoading,
		isSuccess,
		handleReset,
		error,
	} = useFaqGroupUpsert();
	return (
		<Page
			title={!single.isLoading ? `${mode} FAQ group` : ''}
			width="contained"
		>
			{notFound && (
				<ErrorMessage
					error={`The FAQ group with id ${params.id} could not be found. You can create a new one below.`}
				/>
			)}

			<LocalizationEditor
				localizationMap={form.data.name}
				onChange={(value) => form.handleChange('name')(value)}
				renderer={(value, onChange, language) => (
					<TextField
						required
						value={value}
						label={`Group Name (${language})`}
						error={!!form.errors.name}
						placeholder="My new group"
						size="small"
						onChange={(e) => onChange(e.target.value)}
					/>
				)}
			/>

			<TextField
				required
				type="number"
				value={form.data.prio}
				label="Prio"
				error={!!form.errors.prio}
				size="small"
				onChange={(e) => form.handleChange('prio')(Number(e.target.value))}
				InputProps={{
					inputProps: { min: 0, max: 100 },
				}}
			/>

			<Switch
				on={form.data.active}
				onChange={() => form.handleChange('active')(!form.data.active)}
				label="Active"
			/>

			<UpsertButton
				disabled={isLoading}
				isLoading={isLoading}
				onClick={form.handleSubmit}
				mode={mode === 'Update' ? 'update' : 'create'}
			/>

			<StatusSnackbar
				open={isSuccess}
				onClose={handleReset}
				text={`Group ${mode === 'Create' ? 'created' : 'updated'}!`}
			/>

			<ErrorPopup error={error} onClose={handleReset} />
		</Page>
	);
}
