import { button } from './button';
import { color } from './color';
import { device, deviceSize } from './device';
import { font, fontSize } from './font';
import { rounding } from './rounding';
import { space } from './space';

const main = {
	color,
	font,
	fontSize,
	space,
	rounding,
	deviceSize,
	device,
	button,
};

export type Theme = typeof main;

export function invertTheme(theme: Theme): Theme {
	return {
		...theme,
		color: {
			...theme.color,
			white: theme.color.darkGray,
			darkGray: theme.color.white,
		},
	};
}

export const mainTheme = main;
