import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Duration, snakeCaseToHuman } from '@mybonus/public';
import { useState } from 'react';

export type RelativeDateTimeInputProps = {
	initialValue?: Duration;
	onChange: (duration: Duration) => void;
	disabled?: boolean;
	suffixText?: string;
};

const units = ['HOURS', 'DAYS', 'WEEKS'];

export function RelativeDateTimeInput(props: RelativeDateTimeInputProps) {
	let initialUnit: Duration['unit'] = 'HOURS';
	let initialValue = 1;

	if (props.initialValue) {
		initialUnit = props.initialValue.unit;
		initialValue = props.initialValue.value;
	}

	const [unit, setUnit] = useState<Duration['unit']>(initialUnit);
	const [value, setValue] = useState<number>(initialValue);

	function onChange(newValue: Duration) {
		setUnit(newValue.unit);
		setValue(newValue.value);

		props.onChange(newValue);
	}

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<TextField
				value={value}
				sx={{ maxWidth: '80px', marginRight: '8px' }}
				type="number"
				label="Value"
				variant="outlined"
				size="small"
				disabled={props.disabled}
				InputProps={{
					inputProps: { min: 1 },
				}}
				onChange={(e) => {
					e.target.value && onChange({ unit, value: Number(e.target.value) });
				}}
			/>
			<Autocomplete
				value={{ id: unit, label: snakeCaseToHuman(unit) }}
				isOptionEqualToValue={(opt, v) => opt.id === v.id}
				disableClearable
				disabled={props.disabled}
				sx={{ minWidth: '100px' }}
				size="small"
				options={units.map((u) => ({
					id: u,
					label: snakeCaseToHuman(u),
				}))}
				renderInput={(params) => <TextField {...params} label="Unit" />}
				onChange={(_e, v) => {
					value &&
						onChange({
							unit: v.id as Duration['unit'],
							value,
						});
				}}
			/>
			{props.suffixText && (
				<Typography
					color={props.disabled ? 'gray' : 'inherit'}
					sx={{ marginLeft: '8px' }}
				>
					{props.suffixText}
				</Typography>
			)}
		</Box>
	);
}
