import TextField from '@mui/material/TextField';
import type { NumberFieldState } from '@mybonus/public';
import type { FC } from 'react';

import type { FieldConfig } from '../config';

export interface NumberFieldProps {
	fieldConfig: FieldConfig;
	field: NumberFieldState;
	onChange: (field: NumberFieldState) => void;
	disabled?: boolean;
}

export const NumberField: FC<NumberFieldProps> = (props) => {
	console.log(props);
	const op = props.field.state.op;
	switch (op) {
		case 'UNSET':
		case 'TRUTHY':
		case 'FALSY':
		case 'EQ':
		case 'NEQ':
		case 'GT':
		case 'GTE':
		case 'LT':
		case 'LTE':
			return (
				<TextField
					defaultValue={props.field.state.value}
					type="number"
					label="Value"
					variant="outlined"
					size="small"
					disabled={props.disabled}
					onChange={(e) => {
						props.onChange({
							...props.field,
							state: {
								op,
								value: Number(e.target.value),
							},
						});
					}}
				/>
			);
		case 'INSIDE':
		case 'OUTSIDE':
			return <div>Not supported :(</div>;
	}
};
