import { z } from 'zod';

export const OfferValueType = z.enum(['CURRENCY', 'PERCENTAGE']);
export type OfferValueType = z.infer<typeof OfferValueType>;

export const OfferSpec = z.object({
	label: z.string(),
	text: z.string(),
	value: z.number(),
	type: OfferValueType,
});
export type OfferSpec = z.infer<typeof OfferSpec>;

export const OfferOverride = z.object({
	conditions: z.object({
		name: z.string(),
		activeFrom: z.date().nullable(),
		activeTo: z.date().nullable(),
	}),
	spec: OfferSpec,
});
export type OfferOverride = z.infer<typeof OfferOverride>;

export const Offer = z.object({
	id: z.number(),
	main: z.boolean(),
	active: z.boolean(),
	spec: OfferSpec,
	override: OfferOverride.optional(),
});

export type Offer = z.infer<typeof Offer>;

export const BestOffer = z.object({
	isMultipleOffers: z.boolean(),
	isDifferentOffers: z.boolean(),
	value: z.number(),
	text: z.string(),
	offer: Offer,
});
export type BestOffer = z.infer<typeof BestOffer>;
