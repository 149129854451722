import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import type { SelectFieldState } from '@mybonus/public';
import { FC, useReducer, useEffect } from 'react';

import type { FieldConfig } from '../../builder/config';

export interface SelectFieldProps {
	fieldConfig: FieldConfig;
	field: SelectFieldState;
	onChange: (field: SelectFieldState) => void;
	disabled?: boolean;
}

type SelectFieldValues = { id: string; label: string }[];

interface SelectFieldInternalState {
	loading: boolean;
	values: SelectFieldValues;
}

type SelectFieldAction =
	| {
			type: 'loading';
	  }
	| {
			type: 'finished';
			values: SelectFieldValues;
	  };

function selectValueReducer(
	state: SelectFieldInternalState,
	action: SelectFieldAction,
): SelectFieldInternalState {
	switch (action.type) {
		case 'loading':
			return { ...state, loading: true };
		case 'finished':
			return { ...state, loading: false, values: action.values };
	}
}

export const SelectField: FC<SelectFieldProps> = (props) => {
	const { fieldConfig, field, onChange, disabled } = props;
	const [internalState, dispatch] = useReducer(selectValueReducer, {
		loading: true,
		values: [],
	});
	const multiselect = 'multiselect' in fieldConfig && fieldConfig.multiselect;

	useEffect(() => {
		if ('populate' in fieldConfig) {
			fieldConfig
				.populate()
				.then((values) => dispatch({ type: 'finished', values }));
		}
	}, []);

	const defaultValues = internalState.values.filter((v) =>
		field.state.value?.includes(v.id),
	);

	return (
		<Autocomplete
			size="small"
			loading={internalState.loading}
			sx={{ minWidth: '200px' }}
			autoComplete
			autoSelect
			disableClearable
			disabled={disabled}
			multiple={multiselect}
			// TODO: Hacky way of forcing rerender of defaultValue prop
			key={JSON.stringify(defaultValues[0])}
			defaultValue={multiselect ? defaultValues : defaultValues[0]}
			options={internalState.values}
			renderInput={(params) => <TextField {...params} label="Value" />}
			onChange={(_e, value: any) => {
				value &&
					onChange({
						...field,
						state: {
							...field.state,
							value: Array.isArray(value) ? value.map((v) => v.id) : [value.id],
						},
					});
			}}
		/>
	);
};
