import Tooltip from '@mui/material/Tooltip';

import {
	formatRelativeTime,
	formatShort,
	castDate,
} from '../../../helpers/time';

export type DateTimeProps = {
	date: Date | string;
	type?: 'relative' | 'distance' | 'plain';
};

export function DateTime({ date, type }: DateTimeProps) {
	let value;
	const d = castDate(date);

	if (d.getTime() > Date.now() + 1000 * 60 * 60 * 24 * 365 * 10) {
		value = 'forever';
	} else if (type !== 'plain') {
		value = formatRelativeTime(date, type);
	} else {
		value = formatShort(date);
	}

	return (
		<Tooltip title={String(date)}>
			<span>{value}</span>
		</Tooltip>
	);
}
