import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Logout from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import MuiLink from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useAuthContext } from '@mybonus/ui';
import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

export type MenuProps = {
	children?: ReactNode;
};

// TODO: Clean up this mess
export function Menu(props: MenuProps) {
	const [audienceOpen, setAudienceOpen] = useState(false);
	const [bonusesOpen, setBonusesOpen] = useState(false);
	const [messagesOpen, setMessagesOpen] = useState(false);
	const [toolsOpen, setToolsOpen] = useState(false);
	const [contentOpen, setContentOpen] = useState(false);
	const [faqOpen, setFaqOpen] = useState(false);
	const auth = useAuthContext();

	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				variant="permanent"
				anchor="left"
			>
				<List
					dense
					subheader={<ListSubheader component="div">Marketing</ListSubheader>}
				>
					<ListItemButton onClick={() => setAudienceOpen(!audienceOpen)}>
						<ListItemText primary="Audience" />
						{audienceOpen ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={audienceOpen}>
						<List component="div" disablePadding dense>
							<MuiLink component={Link} to="/app/marketing/audiences">
								<ListItemButton sx={{ pl: 4 }}>
									<ListItemText primary="Audiences" />
								</ListItemButton>
							</MuiLink>
							<MuiLink component={Link} to="/app/marketing/audiences/schedule">
								<ListItemButton sx={{ pl: 4 }}>
									<ListItemText primary="Schedule" />
								</ListItemButton>
							</MuiLink>
						</List>
					</Collapse>
					<ListItemButton onClick={() => setBonusesOpen(!bonusesOpen)}>
						<ListItemText primary="Bonuses" />
						{bonusesOpen ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={bonusesOpen}>
						<List component="div" disablePadding dense>
							<MuiLink
								component={Link}
								to="/app/marketing/cashback-shares/list"
							>
								<ListItemButton sx={{ pl: 4 }}>
									<ListItemText primary="Cashback shares" />
								</ListItemButton>
							</MuiLink>
						</List>
					</Collapse>
					<ListItemButton onClick={() => setMessagesOpen(!messagesOpen)}>
						<ListItemText primary="Messages" />
						{messagesOpen ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={messagesOpen}>
						<List component="div" disablePadding dense>
							<MuiLink
								component={Link}
								to="/app/marketing/messages/notifications/list"
							>
								<ListItemButton sx={{ pl: 4 }}>
									<ListItemText primary="Notifications" />
								</ListItemButton>
							</MuiLink>
						</List>
					</Collapse>
					<ListItemButton onClick={() => setToolsOpen(!toolsOpen)}>
						<ListItemText primary="Tools" />
						{toolsOpen ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={toolsOpen}>
						<List component="div" disablePadding dense>
							<MuiLink
								component={Link}
								to="/app/marketing/tools/universal-links"
							>
								<ListItemButton sx={{ pl: 4 }}>
									<ListItemText primary="Generate Universal Links" />
								</ListItemButton>
							</MuiLink>
						</List>
					</Collapse>
				</List>

				<Divider />

				<List
					dense
					subheader={<ListSubheader component="div">CMS</ListSubheader>}
				>
					<ListItemButton onClick={() => setContentOpen(!contentOpen)}>
						<ListItemText primary="Content" />
						{contentOpen ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={contentOpen}>
						<List component="div" disablePadding dense>
							<MuiLink component={Link} to="/app/cms/content">
								<ListItemButton sx={{ pl: 4 }}>
									<ListItemText primary="Static content" />
								</ListItemButton>
							</MuiLink>
							<MuiLink component={Link} to="/app/cms/page">
								<ListItemButton sx={{ pl: 4 }}>
									<ListItemText primary="Pages" />
								</ListItemButton>
							</MuiLink>
						</List>
					</Collapse>

					<ListItemButton onClick={() => setFaqOpen(!faqOpen)}>
						<ListItemText primary="FAQ" />
						{faqOpen ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={faqOpen}>
						<List component="div" disablePadding dense>
							<MuiLink component={Link} to="/app/cms/faq/items">
								<ListItemButton sx={{ pl: 4 }}>
									<ListItemText primary="Items" />
								</ListItemButton>
							</MuiLink>
							<MuiLink component={Link} to="/app/cms/faq/groups">
								<ListItemButton sx={{ pl: 4 }}>
									<ListItemText primary="Groups" />
								</ListItemButton>
							</MuiLink>
						</List>
					</Collapse>
				</List>

				<Divider />

				<List
					subheader={<ListSubheader component="div">Account</ListSubheader>}
				>
					<ListItemButton dense onClick={() => auth?.logout()}>
						<ListItemText primary="Logout" />
						<Logout />
					</ListItemButton>
				</List>
			</Drawer>
			<Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
				{props.children}
			</Box>
		</Box>
	);
}
