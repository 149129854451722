import { styled, css } from '@mybonus/ui';

import type { PageProps } from './Page';

export const Wrapper = styled.div<Omit<PageProps, 'children'>>`
	display: flex;
	flex-direction: column;
	row-gap: ${({ theme }) => theme.space.md}px;

	${({ width }) =>
		width === 'contained'
			? css`
					max-width: 1000px;
			  `
			: ''}
`;
