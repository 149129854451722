import type { ImageResizeInput } from '../types';

export function imageUrlWithSize(baseUrl: string, size: ImageResizeInput): string {
	let param = '';

	if ('h' in size) {
		param = `.${size.format}?h=${size.h}`;
	} else if ('w' in size) {
		param = `.${size.format}?w=${size.w}`;
	} else if ('d' in size) {
		param = '?d=1';
	}

	return `${baseUrl}${param}`;
}
