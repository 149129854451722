import type { BoolFieldState } from '@mybonus/public';
import type { FC } from 'react';

import type { FieldConfig } from '../config';

export interface BoolFieldProps {
	fieldConfig: FieldConfig;
	field: BoolFieldState;
	onChange: (field: BoolFieldState) => void;
	disabled?: boolean;
}

export const BoolField: FC<BoolFieldProps> = () => {
	return <></>;
};
