import DelIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

export type DeleteIconProps = {
	onClick: () => void;
};

export function DeleteIcon(props: DeleteIconProps) {
	return (
		<IconButton size="small" title="Delete" onClick={props.onClick}>
			<DelIcon />
		</IconButton>
	);
}
