import type EasyMDE from 'easymde';
import 'easymde/dist/easymde.min.css';
import { useMemo } from 'react';
import SimpleMDE from 'react-simplemde-editor';

export type CmsContentEditorProps = {
	value: string;
	onChange: (markdown: string) => void;
};

export function CmsContentEditor(props: CmsContentEditorProps) {
	const options = useMemo<EasyMDE.Options>(
		() => ({
			spellChecker: false,
			toolbar: [
				'bold',
				'italic',
				'link',
				'heading',
				'unordered-list',
				'|',
				'preview',
				'guide',
			],
		}),
		[],
	);

	return <SimpleMDE {...props} options={options} />;
}
