import type { LocalizedTextInput } from '@mybonus/public';
import { useAPI, useMutation, useQuery, APIInput, useForm } from '@mybonus/ui';
import { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function useCmsContentUpdate() {
	const params = useParams();
	const { api } = useAPI();

	const singleInput = useMemo<APIInput['admin']['cms']['content']['single']>(
		() => ({ code: String(params.code) }),
		[params.code],
	);

	const single = useQuery(
		'admin.cms.content.single',
		(input) => api.admin.cms.content.single.query(input),
		singleInput,
		{ ttl: false },
	);

	const update = useMutation<APIInput['admin']['cms']['content']['update']>(
		(input) => api.admin.cms.content.update.mutate(input),
	);

	const form = useForm<{
		content: LocalizedTextInput;
		code: string;
	}>({
		onSubmit: () => {
			update.mutate(form.data);
		},
		initialValues: {
			code: params.code,
			content: {},
		},
	});

	useEffect(() => {
		if (single.data) {
			form.setData({
				...form.data,
				content: single.data,
			});
		}
	}, [single.data]);

	function handleReset() {
		single.reset();
		update.reset();
	}

	const isLoading = single.isLoading || update.isLoading;
	const notFound = params.id && !isLoading && !single.data;
	const error = single.error || update.error;

	return {
		form,
		params,
		isLoading,
		update,
		error,
		notFound,
		handleReset,
	};
}
