import { Text } from '../../../primitives';
import { styled } from '../../../theme';
import { AnchorButton } from '../../AnchorButton';
import { Center } from '../../Center';

export const Wrapper = styled(Center)`
	flex-direction: column;

	> * {
		margin-bottom: ${({ theme }) => theme.space.lg}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const DescriptionSection = styled(Center)`
	flex-direction: column;

	> * {
		margin-bottom: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const ClaimHintText = styled(Text).attrs((props) => ({
	...props,
	size: 'sm',
	color: props.theme.color.midGray.base,
}))``;

export const ClaimsButton = styled(AnchorButton).attrs((props) => ({
	...props,
	size: 'xs',
	to: { local: '/account/claim' },
	variant: 'primaryHollow',
	text: 'Saknar du ett köp?',
}))``;
