import { AccessGrant, Account, clearReadthrough } from '@mybonus/public';
import constate from 'constate';
import { useState, useEffect } from 'react';

import { useAPI } from '../api';
import { useIsMounted } from '../hooks/is-mounted';

function useAuth() {
	const isMounted = useIsMounted();
	const [account, setAccount] = useState<Account>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(true);
	const { client } = useAPI();

	useEffect(() => {
		client
			.getAuthAccount()
			.then((account) => {
				if (account && isMounted()) {
					setAccount(account);
				}
			})
			.catch((err: Error) => isMounted() && setError(err))
			.finally(() => isMounted() && setIsLoading(false));
	}, []);

	function login(accessGrant: AccessGrant) {
		client
			.storeAccessGrant(accessGrant)
			.then(() => client.getAuthAccount())
			.then((account) => {
				if (!account) {
					throw new Error('Could not get account');
				}

				clearReadthrough();

				if (isMounted()) {
					setAccount(account);
				}
			});
	}

	function logout() {
		client
			.clearAccessGrant()
			.then(() => {
				setAccount(undefined);
				setError(undefined);
				clearReadthrough();
			})
			.catch((err: Error) => {
				setError(err);
			});
	}

	return { account, error, login, logout, setAccount, isLoading };
}

export const [AuthProvider, useAuthContext] = constate(useAuth);
