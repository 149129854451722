import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

export function BackButton() {
	const navigate = useNavigate();

	return (
		<Box mb={4} pb={2}>
			<Button variant="outlined" onClick={() => navigate(-1)}>
				Back
			</Button>
		</Box>
	);
}
