import { getStoreOpts, getUserCashbackShareOpts, getAudiencesOpts } from '../data';

export const config = {
	fields: [
		{
			type: 'BOOL',
			id: 'emailNewsletter',
			label: 'Wants email newsletter',
			ops: ['TRUTHY', 'FALSY'],
		},
		{
			type: 'DATE',
			id: 'visitDate',
			label: 'Visit date',
			ops: ['GT', 'LT'],
		},
		{
			type: 'BOOL',
			id: 'birthDay',
			label: 'Birthday',
			ops: ['TRUTHY'],
		},
		{
			type: 'BOOL',
			id: 'giftcardPurchaser',
			label: 'Has purchased giftcard',
			ops: ['TRUTHY'],
		},
		{
			type: 'SELECT',
			id: 'gender',
			label: 'Gender',
			ops: ['INCLUDES', 'EXCLUDES'],
			multiselect: true,
			populate: async (): Promise<{ id: string; label: string }[]> => {
				return [
					{ id: 'MALE', label: 'Male' },
					{ id: 'FEMALE', label: 'Female' },
				];
			},
		},
		{
			type: 'DATE',
			id: 'registeredAt',
			label: 'Registered',
			ops: ['GT', 'LT', 'IN_THE_LAST', 'MORE_THAN'],
		},
		{
			type: 'SELECT',
			id: 'platforms',
			label: 'Platforms',
			ops: ['INCLUDES'],
			multiselect: true,
			populate: async (): Promise<{ id: string; label: string }[]> => {
				return [
					{ id: 'APP_ANDROID', label: 'App (Android)' },
					{ id: 'APP_IOS', label: 'App (iOS)' },
					{ id: 'EXT_CHROME', label: 'Extension (Chrome)' },
					{ id: 'WEB', label: 'Web' },
				];
			},
		},
		{
			type: 'NUMBER',
			id: 'age',
			label: 'Age',
			ops: ['GT', 'LT'],
		},
		{
			type: 'NUMBER',
			id: 'purchaseCount',
			label: 'Purchase count',
			ops: ['GT', 'LT', 'TRUTHY'],
		},
		{
			type: 'DATE',
			id: 'lastPurchaseDate',
			label: 'Last purchase date',
			ops: ['GT', 'LT', 'IN_THE_LAST', 'MORE_THAN'],
		},
		{
			type: 'SELECT',
			id: 'purchaseStores',
			label: 'Purchase Stores',
			ops: ['INCLUDES'],
			multiselect: true,
			populate: () => getStoreOpts(),
		},
		{
			type: 'SELECT',
			id: 'favoriteStores',
			label: 'Favorite Stores',
			ops: ['INCLUDES'],
			multiselect: true,
			populate: () => getStoreOpts(),
		},
		{
			type: 'SELECT',
			id: 'userCashbackShares',
			label: 'Cashback Shares',
			ops: ['INCLUDES'],
			populate: () => getUserCashbackShareOpts(),
		},
		{
			type: 'SELECT',
			id: 'audiences',
			label: 'Audiences',
			ops: ['INCLUDES'],
			populate: () => getAudiencesOpts(),
		},
		{
			type: 'NUMBER',
			id: 'userId',
			label: 'User ID',
			ops: ['EQ', 'INCLUDES'],
		},
	],
} as const;

export type Config = typeof config;

export type FieldConfig = Config['fields'][number];
