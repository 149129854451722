import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import type { Query } from '@mybonus/public';
import { useAPI, useMutation, APIInput, APIOutput } from '@mybonus/ui';
import { FC, useEffect } from 'react';

import { ErrorPopup } from '../../layout';
import AudiencePreviewTable from './table';

export type AudiencePreviewProps = {
	query: Query;
};

const AudiencePreview: FC<AudiencePreviewProps> = (props) => {
	const { api } = useAPI();
	const preview = useMutation<
		APIInput['admin']['audience']['preview'],
		APIOutput['admin']['audience']['preview']
	>((input) => api.admin.audience.preview.mutate(input));

	function fetchPreview() {
		preview.mutate({
			query: JSON.stringify(props.query),
		});
	}

	useEffect(() => {
		fetchPreview();
	}, [props.query]);

	return (
		<Box mt={4}>
			<>
				{preview.isLoading && <LinearProgress />}
				<ErrorPopup
					error={preview.error}
					onRetry={fetchPreview}
					onClose={preview.reset}
				/>
				{preview.data && <AudiencePreviewTable preview={preview.data} />}
			</>
		</Box>
	);
};

export default AudiencePreview;
