import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
	copy,
	Op,
	FieldState,
	SelectFieldState,
	NumberFieldState,
	BoolFieldState,
	DateFieldState,
} from '@mybonus/public';
import React, { useState } from 'react';
import isEqual from 'react-fast-compare';

import type { Config } from './config';
import { BoolField } from './fields/bool';
import { DateField } from './fields/date';
import { NumberField } from './fields/number';
import { SelectField } from './fields/select';

export interface FieldProps {
	config: Config;
	field: FieldState;
	onChange: (field: FieldState) => void;
	disabled?: boolean;
}

export const Field: React.FC<FieldProps> = (props) => {
	const [editing, setEditing] = useState(false);
	const [field, setField] = useState<FieldProps['field']>();

	function triggerOnChange(): void {
		setEditing(false);
		if (field) props.onChange(field);
	}

	return (
		<Box sx={{ display: 'flex' }}>
			<FieldInternal
				{...props}
				onChange={(s) => {
					setEditing(true);
					setField(s);
				}}
			/>
			{editing && (
				<Button
					sx={{ marginLeft: '8px', marginTop: '2px' }}
					onClick={triggerOnChange}
				>
					Apply
				</Button>
			)}
		</Box>
	);
};

export const FieldInternal: React.FC<FieldProps> = React.memo(
	(props) => {
		const { config, onChange, field, disabled } = props;
		const fieldConfig = config.fields.find((f) => f.id === field.id);

		if (!fieldConfig) {
			throw new Error(`Unsupported field`);
		}

		const [op, setOp] = useState<Op>(field?.state?.op || fieldConfig.ops[0]);

		function onChangeOp(newOp: Op) {
			setOp(newOp);
			onChange(FieldState.parse({ ...field, state: { op: newOp } }));
		}

		const FieldComp: React.FC<{
			op: Op;
		}> = ({ op }) => {
			switch (fieldConfig.type) {
				case 'NUMBER':
					return (
						<NumberField
							fieldConfig={fieldConfig}
							field={NumberFieldState.parse({
								...field,
								state: { ...field.state, op },
							})}
							onChange={(field) => onChange(FieldState.parse(field))}
							disabled={disabled}
						/>
					);
				case 'SELECT':
					return (
						<SelectField
							fieldConfig={fieldConfig}
							field={SelectFieldState.parse({
								...field,
								state: { ...field.state, op },
							})}
							onChange={(field) => onChange(FieldState.parse(field))}
							disabled={disabled}
						/>
					);
				case 'DATE':
					return (
						<DateField
							fieldConfig={fieldConfig}
							field={DateFieldState.parse({
								...field,
								state: { ...field.state, op },
							})}
							onChange={(field) => onChange(FieldState.parse(field))}
							disabled={disabled}
						/>
					);
				case 'BOOL':
					return (
						<BoolField
							fieldConfig={fieldConfig}
							field={BoolFieldState.parse({
								...field,
								state: { ...field.state, op },
							})}
							onChange={(field) => onChange(FieldState.parse(field))}
							disabled={disabled}
						/>
					);
				default:
					return <div>No widget for fieldConfig.type</div>;
			}
		};

		return (
			<Box sx={{ display: 'flex' }}>
				<Autocomplete
					value={{ id: op, label: op2str(fieldConfig.type, op) }}
					size="small"
					sx={{
						minWidth: '140px',
						maxWidth: '300px',
						marginRight: '8px',
					}}
					autoComplete
					isOptionEqualToValue={(opt, v) => opt.id === v.id}
					disableClearable
					disabled={disabled}
					options={fieldConfig.ops.map((o) => ({
						id: o,
						label: op2str(fieldConfig.type, o),
					}))}
					renderInput={(params) => <TextField {...params} label="Operator" />}
					onChange={(_e, value) => {
						value && onChangeOp(Op.parse(value.id));
					}}
				/>
				{op && <FieldComp op={op} />}
			</Box>
		);
	},
	({ onChange: _, ...prevData }, { onChange: __, ...nextData }) =>
		isEqual(prevData, nextData),
);

function op2str(fieldType: keyof typeof copy.audienceBuilder, op: Op): string {
	const t = copy.audienceBuilder[fieldType];

	if (t && op in t) {
		return t[op as keyof typeof t];
	}

	return op;
}
