import { Wrapper, Track, Bar, Mark, MarkLabel } from './ProgressBar.parts';

export type ProgressBarProps = {
	value: number;
	min: number;
	max: number;
	animateDuration?: number;
	mark?: {
		from: number;
		to: number;
		label: string;
		labelAlign?: 'center' | 'right';
	};
};

export function ProgressBar(props: ProgressBarProps) {
	return (
		<Wrapper hasMark={!!props.mark}>
			<Track>
				<Bar
					width={(props.value / props.max) * 100}
					animateDuration={props.animateDuration}
				/>
				{props.mark && (
					<Mark
						start={(props.mark.from / props.max) * 100}
						width={((props.mark.to - props.mark.from) / props.max) * 100}
					>
						<MarkLabel align={props.mark.labelAlign}>
							{props.mark.label}
						</MarkLabel>
					</Mark>
				)}
			</Track>
		</Wrapper>
	);
}
