import type { Duration } from '@mybonus/public';
import { formatRelative, formatDistance, format } from 'date-fns';

export function formatRelativeTime(
	_d: Date | string,
	type: 'distance' | 'relative' = 'relative',
): string {
	const d = castDate(_d);

	return type === 'relative'
		? formatRelative(d, new Date())
		: formatDistance(d, new Date(), { addSuffix: true });
}

export function formatShort(_d: Date | string): string {
	const d = castDate(_d);

	return format(d, 'yyyy-MM-dd H:mm');
}

export function castDate(d: Date | string): Date {
	return d instanceof Date ? d : new Date(d);
}

export function formatDuration(duration: Duration): string {
	return `${duration.value} ${duration.unit.toLowerCase()}`;
}
