import type { ReactNode } from 'react';

import { Gradient } from '../../primitives';

export type GreenGradientProps = {
	children?: ReactNode;
	angle?: number;
};

export function GreenGradient(
	props: GreenGradientProps & { className?: string },
) {
	return (
		<Gradient
			colors={['#019573', '#1ed761']}
			angle={props.angle}
			className={props.className}
		>
			{props.children}
		</Gradient>
	);
}
