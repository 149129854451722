import ArrowIcon from '@mui/icons-material/ArrowForward';
import SyncIcon from '@mui/icons-material/Sync';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { AudienceSyncDestination, snakeCaseToHuman } from '@mybonus/public';
import { useMutation, APIInput, APIOutput, useAPI } from '@mybonus/ui';
import { useState, useEffect } from 'react';

import { ErrorPopup } from '../../../components';
import AudienceSelector from '../../../components/audience/select';
import {
	AudienceSyncScheduleForm,
	audienceSyncScheduleToAPIInput,
	AudienceSyncScheduleFormState,
} from './schedule';

const AVAILABLE_DESTINATIONS = ['MAILCHIMP'] as const;

export interface SyncJobFormProps {
	onCreate?: (jobId: number) => void;
}

export function SyncJobForm(props: SyncJobFormProps) {
	const { api } = useAPI();
	const create = useMutation<
		APIInput['admin']['audience']['sync']['schedule']['create'],
		APIOutput['admin']['audience']['sync']['schedule']['create']
	>((input) => api.admin.audience.sync.schedule.create.mutate(input));

	const [selectedAudienceId, setSelectedAudienceId] = useState<number>();
	const [destination, setDestination] = useState<AudienceSyncDestination>(
		AVAILABLE_DESTINATIONS[0],
	);
	const [scheduleState, setScheduleState] =
		useState<AudienceSyncScheduleFormState>();

	function onCreate(): void {
		if (selectedAudienceId && destination && scheduleState) {
			create.mutate({
				audienceId: selectedAudienceId,
				destination,
				schedule: audienceSyncScheduleToAPIInput(scheduleState),
			});
		}
	}

	useEffect(() => {
		if (create.data) {
			props.onCreate?.(create.data);
		}
	}, [create.data]);

	return (
		<Paper
			sx={{ p: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
		>
			<ErrorPopup
				error={create.error}
				onClose={create.reset}
				onRetry={() => onCreate()}
			/>
			<Snackbar
				open={!!create.data}
				autoHideDuration={6000}
				onClose={create.reset}
			>
				<Alert onClose={create.reset} severity="success">
					Sync job created!
				</Alert>
			</Snackbar>
			<AudienceSelector onChange={(id) => setSelectedAudienceId(id)} />
			<Box mx={2}>
				<ArrowIcon fontSize="large" sx={{ color: '#555' }} />
			</Box>
			<Autocomplete
				sx={{ minWidth: '260px' }}
				value={{ id: destination, label: snakeCaseToHuman(destination) }}
				isOptionEqualToValue={(opt, v) => opt.id === v.id}
				autoComplete
				autoSelect
				size="medium"
				options={AVAILABLE_DESTINATIONS.map((d) => ({
					id: d,
					label: snakeCaseToHuman(d),
				}))}
				renderInput={(params) => <TextField {...params} label="Destination" />}
				onChange={(_e, value) => {
					value && setDestination(value.id as AudienceSyncDestination);
				}}
			/>
			<Box ml={2}>
				<LoadingButton
					onClick={onCreate}
					disabled={!selectedAudienceId || !destination}
					endIcon={<SyncIcon />}
					size="large"
					loading={create.isLoading}
					loadingPosition="end"
					variant="contained"
					sx={{ padding: '14px 22px' }}
				>
					Sync
				</LoadingButton>
			</Box>
			<Box sx={{ flexBasis: '100%' }}>
				<AudienceSyncScheduleForm
					onChange={(state) => setScheduleState(state)}
				/>
			</Box>
		</Paper>
	);
}

export default SyncJobForm;
