import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { useAPI, useQuery } from '@mybonus/ui';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Page, ErrorPopup, DateTime } from '../../components';
import { formatDuration } from '../../helpers';

export function CashbackShareView() {
	const params = useParams();
	const { api } = useAPI();

	const input = useMemo(() => ({ id: Number(params.id) }), [params.id]);

	const single = useQuery(
		'admin.cashbackShare.construct.single',
		(args) => api.admin.cashbackShare.construct.single.query(args),
		input,
	);

	return (
		<Page title={single.data?.reason}>
			{single.isLoading && <LinearProgress />}
			<ErrorPopup
				error={single.error}
				onRetry={single.load}
				onClose={single.reset}
			/>

			{single.data && (
				<>
					<Typography variant="subtitle2">
						ID #{single.data.id}, Created{' '}
						<DateTime date={single.data.created_at} />
					</Typography>
					<Typography>
						User gets {single.data.cashback_share} % for{' '}
						{formatDuration({
							unit: single.data.duration_unit,
							value: single.data.duration_value,
						})}
					</Typography>
				</>
			)}
		</Page>
	);
}
