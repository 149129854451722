import { styled, css } from '../../theme';
import type { ButtonProps } from './Button.types';

function Base(props: ButtonProps & { className?: string }) {
	return (
		<button
			className={props.className}
			onClick={() => !props.isLoading && props.onClick()}
			type={props.type || 'button'}
			disabled={props.disabled}
		>
			{props.children}
		</button>
	);
}

export const buttonPadding = {
	xs: { x: 14, y: 10 },
	sm: { x: 18, y: 14 },
	md: { x: 24, y: 16 },
	lg: { x: 32, y: 18 },
};

export const Button = styled(Base)`
	border-radius: 500px;
	border: 0;
	margin: 0;
	transition: background-color 0.1s ease-in-out, opacity 0.1s ease-in-out,
		border-color 0.1s ease-in-out;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${(props) =>
		Array.isArray(props.children) ? 'space-between' : 'center'};

	${(props) => props.isLoading && `opacity: .8;`}

	padding: ${(props) => {
		const { x, y } = buttonPadding[props.size || 'md'];
		return `${y}px ${x}px`;
	}};

	${({ disabled }) =>
		disabled
			? css<ButtonProps>`
					cursor: auto;
					background-color: ${({ theme }) => theme.color.lightGray.base};
			  `
			: css<ButtonProps>`
					cursor: ${(props) => (props.isLoading ? 'wait' : 'pointer')};

					background-color: ${({ theme, variant }) => {
						const tmp = theme.button[variant || 'default'];
						return 'bg' in tmp ? tmp.bg.base : theme.color.transparent.base;
					}};

					${({ theme, variant }) => {
						const tmp = theme.button[variant || 'default'];

						return 'border' in tmp
							? css`
									border: 1px solid ${tmp.border.base};
							  `
							: '';
					}}

					&:hover {
						background-color: ${({ theme, variant }) => {
							const tmp = theme.button[variant || 'default'];

							return 'bg' in tmp
								? 'active' in tmp.bg
									? tmp.bg.active
									: tmp.bg.base
								: theme.color.transparent.base;
						}};
					}
			  `}
`;
