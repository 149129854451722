import type { CashbackLog } from '@mybonus/public';

import { ProgressBar } from '../../../ProgressBar';
import {
	Timeline,
	Wrapper,
	InfoRow,
	DateText,
	MinDaysText,
	MaxDaysText,
	ReadyText,
	LockedText,
	DeniedText,
	CollectedText,
} from './Progress.parts';

export type ProgressProps = {
	item: CashbackLog;
};

export function Progress(props: ProgressProps) {
	const { item } = props;
	const { collectibleEstimate } = item;

	const showBar =
		['PENDING', 'APPROVED', 'ACKNOWLEDGED', 'PAID'].includes(item.status) &&
		!['BONUS', 'AFFILIATE_WELCOME', 'FOLLOWER', 'GIFTCARD'].includes(
			item.sourceType,
		);

	const progressValue = {
		min: 0,
		max: collectibleEstimate.maxDays,
		value: collectibleEstimate.progressDays,
	};

	if (item.isCollectible) {
		progressValue.max = 100;
		progressValue.value = 100;
	} else {
		if (progressValue.value >= progressValue.max - 3) {
			progressValue.value = progressValue.max - 3;
		}
	}

	const showTimeline =
		(showBar && item.status === 'PENDING') || item.status === 'APPROVED';
	const minDaysTextLeft =
		(collectibleEstimate.minDays / collectibleEstimate.maxDays) * 100;
	const isSoon =
		collectibleEstimate.progressDays + 3 > collectibleEstimate.minDays;

	function renderRightText() {
		if (item.hasWithdrawalLock) {
			return <LockedText />;
		} else if (item.isCollectible) {
			return <ReadyText />;
		} else if (item.status === 'DENIED') {
			return <DeniedText />;
		} else if (item.status === 'COLLECTED') {
			return <CollectedText />;
		}
	}

	return (
		<Wrapper>
			{showBar && (
				<ProgressBar
					{...progressValue}
					mark={
						item.isCollectible
							? undefined
							: {
									label: isSoon ? 'Snart uttagbar' : 'Uttagbar',
									from: collectibleEstimate.minDays,
									to: collectibleEstimate.maxDays,
									labelAlign: isSoon ? 'right' : 'center',
							  }
					}
				/>
			)}
			{showTimeline && <Timeline />}
			<InfoRow>
				<DateText>{item.date.toISOString().split('T')[0]}</DateText>
				{showTimeline && (
					<>
						{!isSoon && minDaysTextLeft > 30 && (
							<MinDaysText left={minDaysTextLeft}>
								{collectibleEstimate.minDays}d
							</MinDaysText>
						)}
						<MaxDaysText>{collectibleEstimate.maxDays}d</MaxDaysText>
					</>
				)}
				{renderRightText()}
			</InfoRow>
		</Wrapper>
	);
}
