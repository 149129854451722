import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ErrorMessage } from '@mybonus/ui';

import {
	Page,
	ErrorPopup,
	UpsertButton,
	CmsContentEditor,
	Switch,
	StatusSnackbar,
	LocalizationEditor,
} from '../../components';
import { useFaqItemUpsert } from './FaqItemUpsertView.hooks';

export function FaqItemUpsertView() {
	const {
		notFound,
		params,
		single,
		mode,
		form,
		isLoading,
		isSuccess,
		handleReset,
		error,
		groups,
	} = useFaqItemUpsert();
	return (
		<Page title={!single.isLoading ? `${mode} FAQ Item` : ''} width="contained">
			{notFound && (
				<ErrorMessage
					error={`The FAQ item with id ${params.id} could not be found. You can create a new one below.`}
				/>
			)}

			<LocalizationEditor
				localizationMap={form.data.question}
				onChange={(value) => form.handleChange('question')(value)}
				renderer={(value, onChange, language) => (
					<TextField
						required
						value={value}
						label={`Question (${language})`}
						error={!!form.errors.question}
						placeholder="Some question"
						size="small"
						onChange={(e) => onChange(e.target.value)}
					/>
				)}
			/>

			<LocalizationEditor
				localizationMap={form.data.answer}
				onChange={(value) => form.handleChange('answer')(value)}
				renderer={(value, onChange) => (
					<CmsContentEditor value={value} onChange={onChange} />
				)}
			/>

			<Autocomplete
				sx={{ maxWidth: '400px' }}
				value={{
					id: form.data.groupId,
					label:
						groups.data?.find((g) => g.id === form.data.groupId)?.name || '',
				}}
				isOptionEqualToValue={(opt, v) => opt.id === v.id}
				autoComplete
				autoSelect
				size="small"
				options={(groups.data || []).map((g) => ({ id: g.id, label: g.name }))}
				renderInput={(params) => <TextField {...params} label="Group" />}
				onChange={(_e, value) =>
					form.handleChange('groupId')(value && value.id)
				}
			/>

			<TextField
				sx={{ my: 2 }}
				required
				type="number"
				value={form.data.prio}
				label="Prio"
				error={!!form.errors.prio}
				size="small"
				onChange={(e) => form.handleChange('prio')(Number(e.target.value))}
				InputProps={{
					inputProps: { min: 0, max: 100 },
				}}
			/>

			<Switch
				on={form.data.active}
				onChange={() => form.handleChange('active')(!form.data.active)}
				label="Active"
			/>

			<StatusSnackbar
				open={isSuccess}
				onClose={handleReset}
				text={`Item ${mode === 'Create' ? 'created' : 'updated'}!`}
			/>

			<UpsertButton
				disabled={isLoading}
				isLoading={isLoading}
				onClick={form.handleSubmit}
				mode={mode === 'Update' ? 'update' : 'create'}
			/>

			<ErrorPopup error={error} onClose={handleReset} />
		</Page>
	);
}
