import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useAPI, useMutation, APIInput, APIOutput } from '@mybonus/ui';
import { LogoMyBonusGreen } from '@mybonus/ui';
import { useAuthContext } from '@mybonus/ui';
import qrcode from 'qrcode';
import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorPopup } from '../../components';

export function AuthLoginView() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const { api } = useAPI();

	const login = useMutation<
		APIInput['guest']['auth']['credentials']['login'],
		APIOutput['guest']['auth']['credentials']['login']
	>((input) => api.guest.auth.credentials.login.mutate(input));

	const auth = useAuthContext();
	const navigate = useNavigate();

	function onLogin(otp?: string): void {
		login.mutate({
			email: username,
			password,
			otp,
			type: 'ADMIN',
		});
	}

	function resetErrors(): void {
		login.reset();
	}

	const needsMfa =
		auth.account?.type === 'ADMIN' && auth.account?.extra?.mfa !== true;

	useEffect(() => {
		if (login.data) {
			auth.login(login.data);
		}
	}, [login.data]);

	useEffect(() => {
		if (auth.account && !needsMfa) {
			navigate('/app');
		}
	}, [auth.account]);

	return (
		<Box
			sx={{
				display: 'flex',
				height: '100vh',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<ErrorPopup error={login.error} onClose={resetErrors} />

			<MFAPopup
				open={needsMfa}
				install={
					login.data?.otpSecret
						? { otpSecret: login.data?.otpSecret, email: username }
						: undefined
				}
				isLoading={login.isLoading}
				onSubmit={(otp) => onLogin(otp)}
				onAbort={() => auth.logout()}
			/>
			<Paper elevation={24}>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						onLogin();
					}}
				>
					<Grid
						container
						spacing={2}
						direction="column"
						p={8}
						sx={{ width: '500px' }}
					>
						<Grid item xs={12} sx={{ textAlign: 'center' }}>
							<img
								src={LogoMyBonusGreen}
								style={{
									maxWidth: '200px',
									padding: '32px',
									marginBottom: '32px',
								}}
								alt="MyBonus logo"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								sx={{ width: '100%' }}
								autoFocus
								required
								value={username}
								label="Username"
								placeholder="Your username"
								size="small"
								onChange={(e) => setUsername(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								sx={{ width: '100%' }}
								required
								type="password"
								value={password}
								label="Password"
								placeholder="Your password"
								size="small"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<LoadingButton
								type="submit"
								loading={login.isLoading}
								variant="outlined"
							>
								Login
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			</Paper>
		</Box>
	);
}

export type MFAPopupProps = {
	open: boolean;
	install?: {
		otpSecret: string;
		email: string;
	};
	isLoading?: boolean;
	onSubmit: (otp: string) => void;
	onAbort: () => void;
};

function MFAPopup(props: MFAPopupProps) {
	const { install } = props;
	const [otp, setOtp] = useState('');
	const [qrImg, setQrImg] = useState<string | undefined>();

	const uri = useMemo<string | null>(() => {
		if (!install) {
			return null;
		}

		const issuer = encodeURIComponent('MyBonus Admin');
		const account = encodeURIComponent(install?.email);

		return `otpauth://totp/${issuer}:${account}?secret=${install?.otpSecret}&period=30&digits=6&algorithm=SHA1&issuer=${issuer}`;
	}, [install]);

	useEffect(() => {
		if (!uri) {
			return;
		}

		qrcode.toDataURL(uri, (err, imageUrl) => {
			if (err) {
				throw err;
			}

			setQrImg(imageUrl);
		});
	}, [uri]);

	return (
		<Dialog open={props.open}>
			<Box p={4} sx={{ textAlign: 'center' }}>
				<Typography variant="h4">2FA</Typography>

				{!!qrImg && (
					<Box>
						<Typography>
							You need to setup 2FA before you can continue. Use your favorite
							2FA security app to scan the QR code below.
						</Typography>
						<Box p={4}>
							<img src={qrImg} />
						</Box>
					</Box>
				)}

				<Typography>
					Use the pin from your 2FA app and enter it below.
				</Typography>
				<TextField
					sx={{ width: '60%', mt: 2 }}
					autoFocus
					required
					value={otp}
					label="2FA pin code"
					placeholder="000000"
					InputProps={{
						inputProps: {
							maxLength: 6,
						},
					}}
					onChange={(e) => setOtp(e.target.value)}
				/>

				<Box sx={{ mt: 1 }}>
					<LoadingButton
						type="submit"
						loading={props.isLoading}
						disabled={otp.length !== 6}
						onClick={() => props.onSubmit(otp)}
						variant="outlined"
					>
						Submit
					</LoadingButton>
					<LoadingButton onClick={props.onAbort} color="error">
						Abort
					</LoadingButton>
				</Box>
			</Box>
		</Dialog>
	);
}
