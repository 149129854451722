import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import MuiLink from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { useAPI, useQuery } from '@mybonus/ui';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { ErrorPopup } from '../../components/layout';

export interface AudienceSelectorProps {
	onChange?: (id: number) => void;
	value?: number;
	size?: 'small' | 'medium';
	offerPreviewLink?: boolean;
}

const AudienceSelector: FC<AudienceSelectorProps> = (props) => {
	const { api } = useAPI();
	const list = useQuery(
		'admin.audience.list',
		() => api.admin.audience.list.query(),
		undefined,
	);

	const [selectedAudienceId, setSelectedAudienceId] = useState<
		number | undefined
	>(props.value);

	const selectedAudience = (list.data?.items || []).find(
		(a: any) => a.id === selectedAudienceId,
	);
	const value = selectedAudience
		? { id: String(selectedAudience.id), label: selectedAudience.name }
		: null;

	function onSelect(id: number) {
		setSelectedAudienceId(id);
		props.onChange?.(id);
	}

	return (
		<Box>
			<ErrorPopup error={list.error} onClose={list.reset} onRetry={list.load} />
			<Autocomplete
				sx={{ minWidth: '260px' }}
				value={value}
				isOptionEqualToValue={(opt, v) => opt.id === v.id}
				autoComplete
				loading={list.isLoading}
				autoSelect
				size={props.size || 'medium'}
				options={(list.data?.items || []).map((a: any) => ({
					id: String(a.id),
					label: a.name,
				}))}
				renderInput={(params) => <TextField {...params} label="Audience" />}
				onChange={(_e, value) => {
					value && onSelect(Number(value.id));
				}}
			/>

			{props.offerPreviewLink && (
				<Collapse in={!!selectedAudienceId}>
					<MuiLink
						component={Link}
						target="_blank"
						to={`/app/marketing/audiences/${selectedAudienceId}`}
					>
						Preview audience in new tab
					</MuiLink>
				</Collapse>
			)}
		</Box>
	);
};

export default AudienceSelector;
