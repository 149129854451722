import type { ReactNode } from 'react';

import { BackButton } from './BackButton';
import { Wrapper } from './Page.parts';
import { PageTitle } from './PageTitle';

export type PageProps = {
	children?: ReactNode;
	title?: string;
	width?: 'contained';
};

export function Page(props: PageProps) {
	const { children, ...rest } = props;
	return (
		<Wrapper {...rest}>
			<BackButton />
			{props.title && <PageTitle>{props.title}</PageTitle>}
			{children}
		</Wrapper>
	);
}
