import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';

export type UpsertButtonProps = {
	onClick: () => void;
	isLoading?: boolean;
	disabled?: boolean;
	text?: string;
	mode?: 'update' | 'create';
};

export function UpsertButton(props: UpsertButtonProps) {
	const mode = props.mode || 'create';
	const icon = mode === 'create' ? <AddIcon /> : undefined;

	return (
		<Box>
			<LoadingButton
				variant="contained"
				disabled={props.disabled}
				loading={props.isLoading}
				onClick={props.onClick}
				endIcon={icon}
				loadingPosition={icon ? 'end' : undefined}
			>
				{props.text || mode[0]!.toUpperCase() + mode.slice(1)}
			</LoadingButton>
		</Box>
	);
}
