import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import IconButton from '@mui/material/IconButton';

export type ActivateIconProps = {
	isActive: boolean;
	onClick: (newState: boolean) => void;
	disabled?: boolean;
};

export function ActivateIcon(props: ActivateIconProps) {
	return (
		<IconButton
			size="small"
			disabled={props.disabled}
			title={props.isActive ? 'Inactivate' : 'Make active'}
			onClick={() => props.onClick(!props.isActive)}
		>
			{props.isActive ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />}
		</IconButton>
	);
}
