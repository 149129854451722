import type { ReactNode } from 'react';

import { useClickOutside } from '../../hooks';
import { Wrapper, Triangle } from './Popdown.parts';
export { PopdownMountingReference } from './Popdown.parts';

export type PopdownPosition = 'left' | 'right';

export type PopdownProps = {
	open: boolean;
	position?: PopdownPosition;
	arrow?: {
		position?: PopdownPosition;
		color?: string;
		px?: number;
	};
	borderRadius?: number;
	onClose: () => void;
	children?: ReactNode;
};

export function Popdown(props: PopdownProps & { className?: string }) {
	const ref = useClickOutside(props.onClose);
	const { children, className, ...rest } = props;

	return (
		<Wrapper className={className} ref={ref} {...rest}>
			{props.arrow && <Triangle {...rest} />}
			{children}
		</Wrapper>
	);
}
