import { createContext, useContext } from 'react';

import type { ISSRContext, SSRContextProviderProps } from './types';

export const SSRContext = createContext<ISSRContext<unknown>>({
	data: {},
	shouldCollectDataLoadings: false,
	requests: [],
	title: '',
});

export const useSSR = () => useContext(SSRContext);

export function SSRContextProvider(props: SSRContextProviderProps) {
	return (
		<SSRContext.Provider value={props.value}>
			{props.children}
		</SSRContext.Provider>
	);
}
