import { Box } from '../../primitives';
import { styled } from '../../theme';
import { ErrorMessage as _ErrorMessage } from '../ErrorMessage';
import { TextButton } from '../TextButton';

export const Wrapper = styled(Box)``;

export const OrderButton = styled(TextButton).attrs((props) => ({
	...props,
	text: 'Köp presentkort',
	variant: 'alternative',
}))``;

export const ErrorMessage = styled(_ErrorMessage)`
	text-align: center;
`;
