import type { LocalizedTextInput } from '@mybonus/public';
import { useAPI, useMutation, useQuery, APIInput, useForm } from '@mybonus/ui';
import { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function useFaqGroupUpsert() {
	const params = useParams();
	const { api } = useAPI();
	const create = useMutation<APIInput['admin']['faq']['group']['create']>(
		(input) => api.admin.faq.group.create.mutate(input),
	);

	const singleInput = useMemo(() => ({ id: Number(params.id) }), [params.id]);

	const single = useQuery(
		'admin.faq.group.single',
		(input) => api.admin.faq.group.single.query(input),
		singleInput,
		{ preventAutoload: !params.id, ttl: false },
	);

	const mode = single.data ? 'Update' : 'Create';

	const update = useMutation<APIInput['admin']['faq']['group']['update']>(
		(input) => api.admin.faq.group.update.mutate(input),
	);

	const form = useForm<{
		name: LocalizedTextInput;
		prio: number;
		active: boolean;
	}>({
		onSubmit: () => {
			if (single.data?.id) {
				update.mutate({ id: single.data.id, data: form.data });
			} else {
				create.mutate(form.data);
				form.reset();
			}
		},
		validations: {
			name: {
				required: {
					message: 'Group name is required',
				},
			},
			prio: {
				required: {
					message: 'Group prio is required',
				},
			},
		},
		initialValues: {
			prio: 3,
			active: true,
		},
	});

	function handleReset() {
		create.reset();
		update.reset();
	}

	const isSuccess = !!create.isSuccess || !!update.isSuccess;
	const isLoading = single.isLoading || create.isLoading || update.isLoading;
	const error = single.error || create.error || update.error;
	const notFound = params.id && !isLoading && !single.data;

	useEffect(() => {
		if (single.data) {
			form.setData({
				...form.data,
				name: single.data.name,
				prio: single.data.prio,
				active: !!single.data.active,
			});
		}
	}, [single.data]);

	return {
		isSuccess,
		isLoading,
		error,
		notFound,
		params,
		single,
		mode,
		form,
		handleReset,
	};
}
