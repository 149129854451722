import { useState } from 'react';

import { Page } from '../../components';
import { SyncJobForm } from '../../components/audience/sync/job-form';
import { SyncJobList } from '../../components/audience/sync/job-list';

export function AudienceSyncView() {
	const [lastCreatedJobId, setLastCreatedJobId] = useState<number>();

	return (
		<Page title="Audience Sync">
			<SyncJobForm onCreate={(jobId) => setLastCreatedJobId(jobId)} />
			<SyncJobList focusJobId={lastCreatedJobId} />
		</Page>
	);
}
