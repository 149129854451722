import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import type { AudiencePreview } from '@mybonus/public';
import type { FC } from 'react';

export interface AudiencePreviewTableProps {
	preview: AudiencePreview;
	targetedPeopleMsg?: (totalCount: number) => string;
	noMatchesMsg?: string;
}

const AudiencePreviewTable: FC<AudiencePreviewTableProps> = (props) =>
	props.preview.totalCount === 0 ? (
		<EmptyAudiencePreview {...props} />
	) : (
		<Box>
			<Typography variant="subtitle2" mb={2}>
				{(props.targetedPeopleMsg &&
					props.targetedPeopleMsg(props.preview.totalCount)) ||
					`${props.preview.totalCount} people will get this message`}
			</Typography>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Email</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.preview.participants.map((p) => (
							<TableRow
								key={p.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell component="th" scope="row">
									{p.id}
								</TableCell>
								<TableCell>{p.email}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);

interface EmptyAudiencePreviewProps {
	noMatchesMsg?: string;
}

const EmptyAudiencePreview: FC<EmptyAudiencePreviewProps> = (props) => (
	<Box sx={{ textAlign: 'center' }}>
		<Typography variant="h4">No matches :(</Typography>
		<Typography variant="subtitle2">
			{props.noMatchesMsg ||
				'Try changing or removing some of the query rules.'}
		</Typography>
	</Box>
);

export default AudiencePreviewTable;
