import { styled } from '../../theme';
import { Column } from '../Column';

export const Wrapper = styled(Column)`
	> * {
		margin-bottom: ${({ theme }) => theme.space.lg}px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;
