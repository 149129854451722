import TextField from '@mui/material/TextField';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';

export type DateTimeInputProps = Omit<DateTimePickerProps<Date>, 'renderInput'>;

export function DateTimeInput(props: DateTimeInputProps) {
	return (
		<DateTimePicker
			renderInput={(props) => <TextField size="small" {...props} />}
			{...props}
		/>
	);
}
