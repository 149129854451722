import { ImageBonusBuddies, ImageShopGirl, ImageMoneyHand } from '../../assets';
import { LocalImage, Box } from '../../primitives';
import { styled } from '../../theme';
import { CmsContent } from '../CmsContent';
import { Column } from '../Column';
import { FloatBox } from '../FloatBox';

export const $FloatBox = styled(FloatBox)`
	width: 100%;
	padding: 0;
`;

export const TopSection = styled(Box)`
	padding: ${({ theme }) => theme.space.lg}px;
`;

export const Steps = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media ${({ theme }) => theme.device.laptop} {
		padding: ${({ theme }) => theme.space.lg}px;
		justify-content: space-around;
		flex-direction: row;
	}
`;

export const Step = styled(Column)`
	align-items: center;
	max-width: 480px;
	padding: ${({ theme }) => theme.space.md}px;

	@media ${({ theme }) => theme.device.laptop} {
		padding: 0;
		max-width: 260px;
	}
`;

export const Desc = styled(CmsContent)`
	margin-top: ${({ theme }) => theme.space.md}px;
	text-align: center;
`;

export const BottomSection = styled(Box)`
	padding: ${({ theme }) => theme.space.lg}px;
	background-color: ${({ theme }) => theme.color.lightGray.active};
	border-bottom-left-radius: ${({ theme }) => theme.rounding.md}px;
	border-bottom-right-radius: ${({ theme }) => theme.rounding.md}px;
`;

export const BuddiesImage = styled(LocalImage).attrs((props) => ({
	...props,
	uri: ImageBonusBuddies,
}))`
	max-height: 120px;
`;

export const ShopGirlImage = styled(LocalImage).attrs((props) => ({
	...props,
	uri: ImageShopGirl,
}))`
	max-height: 120px;
`;

export const MoneyHandImage = styled(LocalImage).attrs((props) => ({
	...props,
	uri: ImageMoneyHand,
}))`
	max-height: 120px;
`;
