import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import { RuleNode, FieldState } from '@mybonus/public';
import { cloneDeep } from 'lodash';
import type { FC } from 'react';

import type { Config } from './config';
import { Field } from './field';

export function createEmptyRuleNode(): RuleNode {
	return {
		nodeType: 'RULE',
		negated: false,
	};
}

export interface RuleProps {
	config: Config;
	rule: RuleNode;
	onChange: (rule: RuleNode) => void;
	onDelete: () => void;
	disabled?: boolean;
}

export const Rule: FC<RuleProps> = (props) => {
	const { config, onChange, onDelete, rule, disabled } = props;
	console.log('<RULE/>', props);

	function onNegated() {
		onChange?.({ ...cloneDeep(rule), negated: !rule.negated });
	}

	function onFieldSelect(fieldId: string) {
		const newFieldConfig = config.fields.find((f) => f.id === fieldId);

		newFieldConfig &&
			onChange?.({
				...cloneDeep(rule),
				field: FieldState.parse({
					type: newFieldConfig.type,
					id: newFieldConfig.id,
					state: {
						op: newFieldConfig.ops[0],
					},
				}),
			});
	}

	const fieldConfig = config.fields.find((f) => f.id === rule.field?.id);
	const fieldSelectValue = fieldConfig
		? { id: fieldConfig.id, label: fieldConfig.label }
		: undefined;

	return (
		<Box
			className="rule"
			sx={{
				border: '1px solid #f1f1f1',
				borderRadius: '3px',
				padding: '8px',
				margin: '4px 0',
				backgroundColor: '#fff',
			}}
		>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Box>
					<ToggleButton
						size="small"
						value="check"
						selected={rule.negated}
						onChange={onNegated}
					>
						NOT
					</ToggleButton>
				</Box>

				{!disabled && (
					<ButtonGroup>
						<Button
							onClick={onDelete}
							size="small"
							color="error"
							startIcon={<DeleteIcon />}
						>
							Delete
						</Button>
					</ButtonGroup>
				)}
			</Box>
			<Box sx={{ padding: '16px 0', display: 'flex', width: '100%' }}>
				<Autocomplete
					isOptionEqualToValue={(opt, v) => opt.id === v.id}
					value={fieldSelectValue}
					size="small"
					sx={{ maxWidth: '400px', minWidth: '250px', marginRight: '8px' }}
					autoComplete
					autoSelect
					disabled={disabled}
					options={config.fields.map((f) => ({ id: f.id, label: f.label }))}
					renderInput={(params) => (
						<TextField {...params} label="Select field" />
					)}
					onChange={(_e, value) => {
						value && onFieldSelect(value.id);
					}}
				/>
				{rule.field && (
					<Field
						onChange={(state) => {
							onChange({
								...cloneDeep(rule),
								field: FieldState.parse({
									...rule.field,
									...state,
								}),
							});
						}}
						config={config}
						field={rule.field}
						disabled={disabled}
					/>
				)}
			</Box>
		</Box>
	);
};
