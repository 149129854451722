import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import {
	createFutureDate,
	Duration,
	AudienceSyncJobScheduleInput,
} from '@mybonus/public';
import { useState, useEffect } from 'react';

import {
	DateTimeInput,
	DateTimeRangeInput,
	RelativeDateTimeInput,
} from '../../../components';

type SubjectText = {
	singular: string;
	plural: string;
};

export type AudienceSyncScheduleFormState =
	| {
			recurring: false;
			activeFrom: Date;
	  }
	| {
			recurring: true;
			activeFrom: Date;
			activeTo: Date;
			activeHours?: AudienceSyncScheduleActiveHoursFormState;
			rejoinRules?: AudienceSyncScheduleRejoinFormState;
	  };

export type AudienceSyncScheduleFormProps = {
	onChange: (state: AudienceSyncScheduleFormState) => void;
	subject?: SubjectText;
};

export function AudienceSyncScheduleForm(props: AudienceSyncScheduleFormProps) {
	const [activeFrom, setActiveFrom] = useState<Date>(new Date());
	const [recurring, setRecurring] = useState(false);
	const [activeTo, setActiveTo] = useState<Date>(
		createFutureDate(new Date(), '1d'),
	);
	const [rejoinState, setRejoinState] =
		useState<AudienceSyncScheduleRejoinFormState>();
	const [activeHoursState, setActiveHoursState] =
		useState<AudienceSyncScheduleActiveHoursFormState>();

	useEffect(() => {
		props.onChange(
			recurring
				? {
						recurring,
						activeFrom,
						activeTo,
						activeHours: activeHoursState,
						rejoinRules: rejoinState,
				  }
				: { recurring, activeFrom },
		);
	}, [activeFrom, activeTo, recurring, rejoinState, activeHoursState]);

	return (
		<>
			<Box>
				<FormControlLabel
					control={
						<Checkbox
							checked={recurring}
							onChange={() => setRecurring(!recurring)}
						/>
					}
					label="Target new audience members"
				/>
			</Box>
			<Box>
				{!recurring ? (
					<DateTimeInput
						value={activeFrom}
						label="Scheduled at"
						onChange={(v) => v && setActiveFrom(v)}
					/>
				) : (
					<>
						<DateTimeRangeInput
							from={activeFrom}
							to={activeTo}
							label="Active"
							onChange={(from, to) => {
								setActiveFrom(from);
								setActiveTo(to);
							}}
						/>
						<AudienceSyncScheduleRejoinForm
							subject={props.subject}
							onChange={(state) => setRejoinState(state)}
						/>
						<AudienceSyncScheduleActiveHoursForm
							subject={props.subject}
							onChange={(state) => setActiveHoursState(state)}
						/>
					</>
				)}
			</Box>
		</>
	);
}

type AudienceSyncScheduleRejoinFormState =
	| undefined
	| {
			rejoinAfter: Duration;
			rejoinLimit: number;
	  };

type AudienceSyncScheduleRejoinFormProps = {
	subject?: SubjectText;
	onChange?: (state: AudienceSyncScheduleRejoinFormState) => void;
};

function AudienceSyncScheduleRejoinForm(
	props: AudienceSyncScheduleRejoinFormProps,
) {
	const [allowRejoin, setAllowRejoin] = useState(false);
	const [rejoinAfter, setRejoinAfter] = useState<Duration>({
		unit: 'HOURS',
		value: 1,
	});
	const [joinLimit, setJoinLimit] = useState(5);

	useEffect(() => {
		props.onChange?.(
			allowRejoin
				? {
						rejoinAfter,
						rejoinLimit: joinLimit - 1,
				  }
				: undefined,
		);
	}, [allowRejoin, rejoinAfter, joinLimit]);

	return (
		<>
			<Box>
				<FormControlLabel
					control={
						<Checkbox
							checked={allowRejoin}
							onChange={() => setAllowRejoin(!allowRejoin)}
						/>
					}
					label={`Allow members to receive the ${
						props.subject?.singular || 'message'
					} multiple times`}
				/>
			</Box>
			{allowRejoin && (
				<Paper sx={{ p: 1, my: 1 }}>
					<Typography>
						Minimum delay from last {props.subject?.singular || 'message'}{' '}
						before next one
					</Typography>
					<Box sx={{ mt: 1 }}>
						<RelativeDateTimeInput
							initialValue={rejoinAfter}
							onChange={(v) => setRejoinAfter(v)}
						/>
					</Box>
					<Box sx={{ mt: 1 }}>
						<TextField
							sx={{ my: 2, minWidth: '300px' }}
							required
							type="number"
							value={joinLimit}
							label={`Max number of ${props.subject?.plural || 'messages'}`}
							size="small"
							onChange={(e) => setJoinLimit(Number(e.target.value))}
							InputProps={{
								inputProps: { min: 1 },
							}}
						/>
					</Box>
				</Paper>
			)}
		</>
	);
}

type AudienceSyncScheduleActiveHoursFormState =
	| undefined
	| {
			fromHour: number;
			activeHours: number;
	  };

type AudienceSyncScheduleActiveHoursFormProps = {
	onChange?: (state: AudienceSyncScheduleActiveHoursFormState) => void;
	subject?: SubjectText;
};

function AudienceSyncScheduleActiveHoursForm(
	props: AudienceSyncScheduleActiveHoursFormProps,
) {
	const [allowActiveHours, setAllowActiveHours] = useState(false);
	const [fromHour, setFromHour] = useState(9);
	const [activeHours, setActiveHours] = useState(12);

	useEffect(() => {
		if (!allowActiveHours) {
			props.onChange?.(undefined);
		} else {
			props.onChange?.({
				fromHour,
				activeHours,
			});
		}
	}, [allowActiveHours, fromHour, activeHours]);

	return (
		<>
			<Box>
				<FormControlLabel
					control={
						<Checkbox
							checked={allowActiveHours}
							onChange={() => setAllowActiveHours(!allowActiveHours)}
						/>
					}
					label={`Only let members receive the ${
						props.subject?.singular || 'message'
					} during specific hours`}
				/>
			</Box>
			{allowActiveHours && (
				<Paper sx={{ p: 1, my: 1 }}>
					<TextField
						sx={{ my: 2 }}
						required
						type="number"
						value={fromHour}
						label="From hour (0-23)"
						size="small"
						onChange={(e) => setFromHour(Number(e.target.value))}
						InputProps={{
							inputProps: { min: 0, max: 23 },
						}}
					/>
					<TextField
						sx={{ my: 2, ml: 1 }}
						required
						type="number"
						value={activeHours}
						label="Active hours (1-23h)"
						size="small"
						onChange={(e) => setActiveHours(Number(e.target.value))}
						InputProps={{
							inputProps: { min: 1, max: 23 },
						}}
					/>
				</Paper>
			)}
		</>
	);
}

export function audienceSyncScheduleToAPIInput(
	state: AudienceSyncScheduleFormState,
): AudienceSyncJobScheduleInput {
	return AudienceSyncJobScheduleInput.parse({
		recurring: state && state.recurring ? 15 : 0,
		activeFrom: state.activeFrom,
		activeTo: state.recurring ? state.activeTo : null,
		activeHours:
			state.recurring && state.activeHours
				? {
						fromHour: state.activeHours.fromHour,
						activeHours: state.activeHours.activeHours,
				  }
				: undefined,
		rejoinRules:
			state.recurring && state.rejoinRules
				? {
						rejoinAfter: state.rejoinRules.rejoinAfter,
						rejoinLimit: state.rejoinRules.rejoinLimit,
				  }
				: undefined,
	});
}
