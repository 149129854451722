import constate from 'constate';
import { useMemo, useState, useEffect } from 'react';

import {
	APIOutput,
	useAPI,
	useMutation,
	useQuery,
	type APIInput,
} from '../api';
import { useAuthContext } from './auth';

function useFavorites() {
	const { api } = useAPI();
	const auth = useAuthContext();
	const [favorites, setFavorites] = useState<
		APIOutput['user']['favorite']['listIds']
	>({
		store: [],
	});

	const favoriteQuery = useQuery(
		'user.favorite.listIds',
		(args) => api.user.favorite.listIds.query(args),
		undefined,
		{ preventSSRLoad: true, preventAutoload: !auth.account },
	);

	useEffect(() => {
		if (favoriteQuery.data) {
			setFavorites(favoriteQuery.data);
		}
	}, [favoriteQuery.data]);

	const favoriteMutation = useMutation<APIInput['user']['favorite']['add']>(
		(args) => api.user.favorite.add.mutate(args),
	);
	const unfavoriteMutation = useMutation<
		APIInput['user']['favorite']['remove']
	>((args) => api.user.favorite.remove.mutate(args));

	const isMutating = useMemo(
		() => favoriteMutation.isLoading || unfavoriteMutation.isLoading,
		[favoriteMutation.isLoading, unfavoriteMutation.isLoading],
	);

	function isFavorite(storeId?: number): boolean {
		if (!storeId) {
			return false;
		}

		if (favoriteQuery.isLoading || !favoriteQuery.data) {
			return false;
		}

		return favorites.store.includes(storeId);
	}

	function toggleFavorite(storeId: number) {
		if (isMutating) {
			return;
		}

		if (isFavorite(storeId)) {
			unfavoriteMutation.mutate({ type: 'store', id: storeId });

			setFavorites({
				...favorites,
				store: favorites.store.filter((id) => id !== storeId),
			});
		} else {
			favoriteMutation.mutate({ type: 'store', id: storeId });

			setFavorites({
				...favorites,
				store: [...favorites.store, storeId],
			});
		}
	}

	return {
		isLoading: favoriteQuery.isLoading,
		toggleFavorite,
		isFavorite,
	};
}

export const [FavoriteProvider, useFavoriteContext] = constate(useFavorites);
