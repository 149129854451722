import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import MuiLink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
	useAPI,
	useQuery,
	useMutation,
	usePagination,
	APIInput,
	Row,
} from '@mybonus/ui';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
	Page,
	ErrorPopup,
	Paginate,
	Confirm,
	DateTime,
} from '../../components';

export function AudienceListView() {
	const [deleteId, setDeleteId] = useState<number>();
	const [deletedIds, setDeletedIds] = useState<number[]>([]);
	const { pagination: input, setPage } = usePagination();
	const { api } = useAPI();

	const list = useQuery(
		'admin.audience.list',
		(args) => api.admin.audience.list.query(args),
		input,
	);

	const remove = useMutation<APIInput['admin']['audience']['remove']>((input) =>
		api.admin.audience.remove.mutate(input),
	);

	return (
		<Page title="Audiences">
			<Row apart>
				<Link to="/app/marketing/audiences/create">
					<Button variant="contained">New Audience</Button>
				</Link>

				<Paginate state={list.data?.pagination} setPage={setPage} />
			</Row>
			{list.isLoading && <LinearProgress />}

			<ErrorPopup error={list.error} onRetry={list.load} onClose={list.reset} />

			<Confirm
				open={!!deleteId || remove.isLoading}
				onConfirm={() => {
					remove.mutate({ id: Number(deleteId) });
					setDeleteId(undefined);
					setDeletedIds([...deletedIds, Number(deleteId)]);
				}}
				onClose={() => setDeleteId(undefined)}
				title="Delete audience?"
				message="Are you sure you want to delete this audience?"
				isLoading={remove.isLoading}
			/>

			<ErrorPopup error={remove.error} onClose={remove.reset} />

			{list && (
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }}>
						<TableHead>
							<TableRow>
								<TableCell>ID</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Created</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{list?.data?.items.map((row) => (
								<TableRow
									key={row.id}
									sx={{
										'&:last-child td, &:last-child th': { border: 0 },
										...(deletedIds.indexOf(row.id) !== -1
											? { opacity: 0.3, pointerEvents: 'none' }
											: {}),
									}}
								>
									<TableCell component="th" scope="row">
										{row.id}
									</TableCell>
									<TableCell>
										<MuiLink
											component={Link}
											to={`/app/marketing/audiences/${row.id}`}
										>
											{row.name}
										</MuiLink>
									</TableCell>
									<TableCell>
										<DateTime date={row.created_at} />
									</TableCell>
									<TableCell>
										<IconButton
											size="small"
											aria-label="delete"
											onClick={() => setDeleteId(row.id)}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<Grid container mt={2} direction="row" justifyContent="flex-end">
				<Paginate state={list.data?.pagination} setPage={setPage} />
			</Grid>
		</Page>
	);
}
