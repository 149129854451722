import { ExtendableError } from 'extendable-error';

export class BaseError extends ExtendableError {
	constructor(msg?: string) {
		super(msg);
	}

	toString(): string {
		return `${this.name}: ${this.message || '<no message>'}`;
	}
}
