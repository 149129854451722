import { Box, Gradient, Text } from '../../primitives';
import { styled, keyframes, css } from '../../theme';
import { Column } from '../Column';

export const Wrapper = styled(Column)<{ hasMark: boolean }>`
	padding-bottom: ${({ hasMark }) => (hasMark ? 20 : 0)}px;
`;

export const Track = styled(Box)`
	border-radius: 500px;
	height: 18px;
	border: 1px solid ${({ theme }) => theme.color.lightGray.base};
	width: 100%;
	position: relative;
`;

const progress = keyframes`
	0% { width: 0% }
	100% { width: 100% }
`;

export const Bar = styled(Gradient).attrs((props) => ({
	...props,
	colors: [props.theme.color.green.base, props.theme.color.green.active],
}))<{ width: number; animateDuration?: number }>`
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 500px;
	z-index: 1;
	${({ animateDuration }) =>
		animateDuration
			? css`
					animation: ${progress} ${animateDuration}s ease-in-out;
			  `
			: css``}
	width: ${({ width }) => width}%;
`;

export const Mark = styled(Box)<{ start: number; width: number }>`
	height: 100%;
	background: repeating-linear-gradient(
		-60deg,
		transparent,
		transparent 4px,
		${({ theme }) => theme.color.lightGray.base} 4px,
		${({ theme }) => theme.color.lightGray.base} 7px
	);
	position: absolute;
	top: 0;
	border-radius: 500px;
	left: ${({ start }) => start}%;
	width: ${({ width }) => width}%;
`;

export const MarkLabel = styled(Text).attrs((props) => ({
	...props,
	size: 'sm',
	color: props.theme.color.midGray.base,
}))<{ align?: 'center' | 'right' }>`
	position: absolute;
	bottom: -20px;
	white-space: nowrap;

	${({ align }) => {
		switch (align) {
			case 'right':
				return css`
					right: 0;
				`;
			case 'center':
			default:
				return css`
					left: 50%;
					transform: translateX(-50%);
				`;
		}
	}}
`;
