import { useAPI, useQuery, useMutation, APIInput } from '@mybonus/ui';
import { useState } from 'react';

export function usePageListView() {
	const { api } = useAPI();
	const [deleteId, setDeleteId] = useState<number>();
	const [deletedIds, setDeletedIds] = useState<number[]>([]);

	const list = useQuery(
		'admin.cms.page.list',
		(args) => api.admin.cms.page.list.query(args),
		undefined,
		{ ttl: false },
	);

	const remove = useMutation<APIInput['admin']['cms']['page']['remove']>(
		(input) => api.admin.cms.page.remove.mutate(input),
	);

	const inactivate = useMutation<APIInput['admin']['cms']['page']['update']>(
		(input) => api.admin.cms.page.update.mutate(input),
		{
			onSuccess: () => list.load({ refetch: true, bypassCache: true }),
		},
	);

	function handleInactivate(id: number, active: boolean) {
		inactivate.mutate({ id, data: { active: !active } });
	}

	return {
		list,
		remove,
		handleInactivate,
		deleteId,
		deletedIds,
		setDeleteId,
		setDeletedIds,
	};
}
