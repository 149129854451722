import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { ErrorPopup } from '../../layout';
import { useHeroSelect } from './HeroSelect.hooks';

export type HeroSelectProps = {
	onChange?: (id: number | null) => void;
	value?: number | null;
	size?: 'small' | 'medium';
	error?: string | boolean;
};

export function HeroSelect(props: HeroSelectProps) {
	const { list, value, onSelect } = useHeroSelect(props);

	return (
		<Box>
			<ErrorPopup error={list.error} onClose={list.reset} onRetry={list.load} />
			<Autocomplete
				isOptionEqualToValue={(opt, v) => opt.id === v.id}
				sx={{ minWidth: '260px' }}
				value={value}
				autoComplete
				loading={list.isLoading}
				autoSelect
				size={props.size || 'small'}
				options={(list.data || []).map((h) => ({
					id: String(h.id),
					label: `${h.name} (#${h.id})`,
				}))}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Hero"
						error={!!props.error}
						helperText={
							typeof props.error === 'string' ? props.error : undefined
						}
					/>
				)}
				onChange={(_e, value) => {
					onSelect(value ? Number(value.id) : null);
				}}
			/>
		</Box>
	);
}
