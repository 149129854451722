import { readthrough, hashObject } from '@mybonus/public';
import { useSSR } from '@mybonus/ssr';
import { useState, useEffect, useCallback } from 'react';

import { useIsMounted } from '../hooks/is-mounted';
import type { APIError } from './context';

// TODO: Prevent race conditions when issuing new requests before
// previous requests has finished.
export function useQuery<TData, TInput>(
	path: string,
	source: (input: TInput) => Promise<TData>,
	input: TInput,
	opts?: {
		initialState?: TData;
		ttl?: string | false;
		mergeData?: (data: TData | undefined, newData: TData) => TData;
		preventAutoload?: boolean;
		preventSSRLoad?: boolean;
	},
) {
	const ssr = useSSR();
	const isMounted = useIsMounted();
	const cacheKey = `${path}-${hashObject(input)}`;

	const [error, setError] = useState<APIError | undefined>();
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<TData | undefined>(
		(ssr.data[cacheKey] as TData) || opts?.initialState,
	);
	const load = useCallback(
		async (loadOpts?: { bypassCache?: boolean; refetch?: boolean }) => {
			setIsLoading(true);

			try {
				const newData = await readthrough(
					cacheKey,
					typeof opts?.ttl !== 'undefined' ? opts.ttl : '5m',
					() => source(input),
					{ bypassCache: loadOpts?.bypassCache },
				);

				if (isMounted()) {
					setData(
						opts?.mergeData && !loadOpts?.refetch
							? opts.mergeData(data, newData)
							: newData,
					);
				}
			} catch (err) {
				isMounted() && setError(err as APIError);
			} finally {
				isMounted() && setIsLoading(false);
			}
		},
		[input, source, opts],
	);

	async function loadOnServer() {
		const shouldPrevent = opts?.preventSSRLoad || opts?.preventAutoload;
		if (!shouldPrevent) {
			const loadedData = await source(input);
			ssr.data[cacheKey] = loadedData;
		}
	}

	function reset() {
		setIsLoading(false);
		setError(undefined);
		setData((ssr.data[cacheKey] as TData) || opts?.initialState);
	}

	// Collect requests for SSR
	if (ssr.shouldCollectDataLoadings) {
		ssr.requests.push(loadOnServer());
	}

	useEffect(() => {
		const hasSSRData = typeof ssr.data[cacheKey] !== 'undefined';

		if (!hasSSRData && !opts?.preventAutoload) {
			load();
		}
	}, [input]);

	// Trigger load when we're not preventing autoload anymore, and
	// there is no data (first load)
	useEffect(() => {
		if (!opts?.preventAutoload && !data) {
			load();
		}
	}, [opts?.preventAutoload]);

	useEffect(() => {
		if (opts?.initialState) {
			setData(opts?.initialState);
		}
	}, [opts?.initialState]);

	return {
		data,
		isLoading,
		error,
		isError: !!error,
		isSuccess: !!data,
		load,
		reset,
	};
}

export function useMutation<TInput, TData = unknown>(
	mutation: (input: TInput) => Promise<TData>,
	opts?: {
		onSuccess?: (args: { input: TInput; data: TData }) => void;
	},
) {
	const isMounted = useIsMounted();

	const [error, setError] = useState<APIError | undefined>();
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<TData | undefined>();
	const [isSuccess, setIsSuccess] = useState<number | undefined>(undefined);

	async function mutate(input: TInput) {
		setIsLoading(true);

		try {
			const data = await mutation(input);

			if (isMounted()) {
				setData(data);
				setIsSuccess(Date.now());
				opts?.onSuccess?.({ input, data });
			}
		} catch (_err) {
			const err = _err as APIError;
			if (isMounted()) {
				setError(err);
				setIsSuccess(undefined);
			}
		} finally {
			isMounted() && setIsLoading(false);
		}
	}

	function reset() {
		setData(undefined);
		setError(undefined);
		setIsSuccess(undefined);
	}

	return {
		data,
		isLoading,
		error,
		isError: !!error,
		isSuccess,
		mutate,
		reset,
	};
}
