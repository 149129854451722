import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useAPI, useQuery } from '@mybonus/ui';
import { useState } from 'react';

import { ErrorPopup } from '../../layout';

export type StoreSelectProps = {
	onChange?: (id: number) => void;
	value?: number;
	size?: 'small' | 'medium';
};

export function StoreSelect(props: StoreSelectProps) {
	const { api } = useAPI();

	const list = useQuery(
		'admin.store.listSlim',
		() => api.admin.store.listSlim.query(),
		undefined,
	);

	const [selectedStoreId, setSelectedStoreId] = useState<number | undefined>(
		props.value,
	);

	const selectedStore = (list.data || []).find((s) => s.id === selectedStoreId);
	const value = selectedStore
		? {
				id: String(selectedStore.id),
				label: `${selectedStore.name} (#${selectedStore.id})`,
		  }
		: null;

	function onSelect(id: number) {
		setSelectedStoreId(id);
		props.onChange?.(id);
	}

	return (
		<Box>
			<ErrorPopup error={list.error} onClose={list.reset} onRetry={list.load} />
			<Autocomplete
				isOptionEqualToValue={(opt, v) => opt.id === v.id}
				sx={{ minWidth: '260px' }}
				value={value}
				autoComplete
				loading={list.isLoading}
				autoSelect
				size={props.size || 'small'}
				options={(list.data || []).map((s) => ({
					id: String(s.id),
					label: `${s.name} (#${s.id})`,
				}))}
				renderInput={(params) => <TextField {...params} label="Store" />}
				onChange={(_e, value) => {
					value && onSelect(Number(value.id));
				}}
			/>
		</Box>
	);
}
