import { Box } from '../../primitives';
import { styled } from '../../theme';

export const Wrapper = styled(Box)`
	display: flex;

	> * {
		margin-right: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-right: 0;
		}
	}
`;
