export const copy = {
	anonymizedField: 'ANONYMIZED',
	paymentMessage: (userId: number): string => `Inbetalning (${userId})`,
	noName: 'Namnlös',
	upTo: 'Upp till',
	personalCashbackShare: 'Personlig höjning',
	audienceBuilder: {
		BOOL: {
			UNSET: 'Unset',
			TRUTHY: 'True',
			FALSY: 'False',
		},
		DATE: {
			UNSET: 'Unset',
			EQ: 'Equals',
			NEQ: 'Not equals',
			GT: 'After',
			GTE: 'After or on',
			LT: 'Before',
			LTE: 'Before or on',
			IN_THE_LAST: 'In the last',
			MORE_THAN: 'More than',
			INSIDE: 'Between',
			OUTSIDE: 'Not between',
		},
		NUMBER: {
			INSIDE: 'Between',
			OUTSIDE: 'Not between',
			UNSET: 'Unset',
			TRUTHY: 'Non-zero',
			FALSY: 'Zero or not set',
			EQ: 'Equals',
			NEQ: 'Not equals',
			GT: 'Greater than',
			GTE: 'Greater than or equal to',
			LT: 'Less than',
			LTE: 'Less than or equal to',
		},
		SELECT: {
			INCLUDES: 'Includes',
			EXCLUDES: 'Excludes',
		},
		TEXT: {
			UNSET: 'Unset',
			TRUTHY: 'Is set',
			STARTS_WITH: 'Starts with',
			ENDS_WITH: 'Ends with',
			CONTAINS: 'Contains',
			EQ: 'Equals',
			NEQ: 'Not equals',
		},
	},
};
