import { Box, Text } from '../../../../primitives';
import { styled } from '../../../../theme';
import { Column } from '../../../Column';
import { Row } from '../../../Row';

export const Wrapper = styled(Column)`
	> * {
		margin-bottom: 4px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const Timeline = styled(Box)`
	width: 100%;
	height: 7px;
	border-bottom-left-radius: 500px;
	border-bottom-right-radius: 500px;
	border: 1px solid ${({ theme }) => theme.color.midGray.base};
	border-top-color: transparent;
	margin-top: -${({ theme }) => theme.space.xs}px;
`;

export const InfoRow = styled(Row)`
	position: relative;
`;

export const DateText = styled(Text).attrs((props) => ({
	...props,
	size: 'sm',
	color: props.theme.color.midGray.base,
}))``;

export const MinDaysText = styled(Text).attrs((props) => ({
	...props,
	size: 'sm',
	color: props.theme.color.midGray.base,
}))<{ left: number }>`
	position: absolute;
	transform: translateX(-50%);
	left: ${({ left }) => left}%;
`;

const RightText = styled(Text).attrs((props) => ({
	...props,
	size: 'sm',
}))`
	position: absolute;
	right: 0;
`;

export const MaxDaysText = styled(RightText).attrs((props) => ({
	...props,
	color: props.theme.color.midGray.base,
}))``;

export const ReadyText = styled(RightText).attrs((props) => ({
	...props,
	color: props.theme.color.green.base,
	children: 'Redo för utbetalning',
}))``;

export const LockedText = styled(RightText).attrs((props) => ({
	...props,
	color: props.theme.color.yellow.base,
	children: 'Bearbetas',
}))``;

export const DeniedText = styled(RightText).attrs((props) => ({
	...props,
	color: props.theme.color.red.base,
	children: 'Nekad',
}))``;

export const CollectedText = styled(RightText).attrs((props) => ({
	...props,
	color: props.theme.color.midGray.base,
	children: 'Utbetald',
}))``;
