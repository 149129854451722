import { useMemo } from 'react';

import { AnchorButton } from '../AnchorButton';
import { LoadMoreArrow } from '../LoadMoreArrow';
import { NoContent } from '../NoContent';
import { useFavoriteList } from './FavoriteList.hooks';
import { List, Item } from './FavoriteList.parts';

export type FavoriteListProps = {
	onSelect: (store: { id: number; name: string }) => void;
	onNoContentClick: () => void;
	isVisible: boolean;
	limit?: number;
};

export function FavoriteList(
	props: FavoriteListProps & { className?: string },
) {
	const opts = useMemo(
		() => ({ isVisible: props.isVisible, limit: props.limit }),
		[props.isVisible, props.limit],
	);
	const { list, hasMore, loadMore, isLoading, isInitialLoad } =
		useFavoriteList(opts);

	return (
		<List ready={!isInitialLoad} className={props.className}>
			{!isInitialLoad && !list.length ? (
				<NoContent
					message="Lägg till favoritbutiker genom att trycka på hjärt-symbolen när du besöker en butik. På så vis blir blir du meddelad när nya kampanjer kommer ut."
					content={
						<AnchorButton
							variant="primaryHollow"
							to={{ onClick: props.onNoContentClick }}
							text="Upptäck butiker"
						/>
					}
				/>
			) : (
				<>
					{list.map((store, i) => (
						<Item
							isFirst={i === 0}
							key={store.id}
							store={store}
							onClick={() => props.onSelect(store)}
						/>
					))}

					{hasMore && (
						<LoadMoreArrow onClick={loadMore} isLoading={isLoading} />
					)}
				</>
			)}
		</List>
	);
}
