import { useState, useEffect, useMemo } from 'react';

import { useAPI, useQuery } from '../../api';
import { usePagination } from '../../hooks';

export function useFavoriteList(opts: { isVisible: boolean; limit?: number }) {
	const { api } = useAPI();
	const { pagination, setPage, page } = usePagination(1, opts.limit || 5);
	const [isInitialLoad, setIsInitialLoad] = useState(true);

	const input = useMemo(
		() => ({
			...pagination,
			filter: { isFavorite: true as const },
		}),
		[pagination],
	);

	const favorites = useQuery(
		'user.favorite.list',
		(args) => api.user.favorite.listStores.query(args),
		input,
		{
			mergeData: (data, newData) =>
				data
					? { ...data, ...newData, items: data.items.concat(newData.items) }
					: newData,
			preventAutoload: !opts.isVisible,
			ttl: false,
		},
	);

	useEffect(() => {
		if (favorites.data) {
			setIsInitialLoad(false);
		}
	}, [favorites.data]);

	function loadMore() {
		setPage(page + 1);
	}

	useEffect(() => {
		if (!opts.isVisible) {
			setPage(1);
			setIsInitialLoad(true);
			favorites.reset();
		}
	}, [opts.isVisible]);

	const list = favorites.data?.items || [];
	const hasMore =
		favorites.data &&
		favorites.data.pagination.page < favorites.data.pagination.pages;

	return {
		isLoading: favorites.isLoading,
		isInitialLoad,
		list,
		hasMore,
		loadMore,
	};
}
