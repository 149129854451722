import { raw } from '../../api';

export function getStoreOpts(): Promise<{ id: string; label: string }[]> {
	return raw.admin.store.listSlim
		.query()
		.then((stores) => stores.map((s) => ({ id: String(s.id), label: `${s.name} (#${s.id})` })));
}

export function getCategoryOpts(): Promise<{ id: string; label: string }[]> {
	return raw.guest.category.list.query().then((categories) =>
		categories.map((c) => ({
			id: String(c.id),
			label: c.name,
		})),
	);
}

export function getUserCashbackShareOpts(): Promise<{ id: string; label: string }[]> {
	return raw.admin.cashbackShare.construct.listSlim.query().then((list) =>
		list.map((c) => ({
			id: String(c.id),
			label: c.reason,
		})),
	);
}

export function getAudiencesOpts(): Promise<{ id: string; label: string }[]> {
	return raw.admin.audience.list.query().then((list) =>
		list.items.map((a) => ({
			id: String(a.id),
			label: a.name,
		})),
	);
}
