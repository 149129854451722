import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { GroupNode, TreeNode, Conjunction } from '@mybonus/public';
import { cloneDeep } from 'lodash';
import type React from 'react';

import type { Config } from '../builder/config';
import { Rule, createEmptyRuleNode } from './rule';

export interface GroupProps {
	group: GroupNode;
	root?: boolean;
	config: Config;
	onChange?: (group: GroupNode) => void;
	onDelete: () => void;
	disabled?: boolean;
}

export function createEmptyGroupNode(): GroupNode {
	return {
		nodeType: 'GROUP',
		conjunction: 'AND',
		negated: false,
		children: [],
	};
}

export const Group: React.FC<GroupProps> = (props) => {
	const { config, onChange, onDelete, root = false, group, disabled } = props;

	console.log('<GROUP/>', props);

	function onAddGroup(): void {
		console.log('Adding group!');
		const cGroup = cloneDeep(group);

		onChange?.({
			...cGroup,
			children: [...cGroup.children, createEmptyGroupNode()],
		});
	}

	function onAddRule(): void {
		console.log('Adding rule!');
		const cGroup = cloneDeep(group);
		onChange?.({
			...cGroup,
			children: [...cGroup.children, createEmptyRuleNode()],
		});
	}

	function onNegated(): void {
		console.log('onNegated');
		onChange?.({ ...cloneDeep(group), negated: !group.negated });
	}

	function onConjunctionChange(conjunction: Conjunction): void {
		onChange?.({
			...cloneDeep(group),
			conjunction,
		});
	}

	function onChildNodeChange(i: number, child: TreeNode): void {
		const cGroup = cloneDeep(group);
		cGroup.children.splice(i, 1, child);
		onChange?.(cGroup);
	}

	function onChildNodeDelete(i: number): void {
		const cGroup = cloneDeep(group);
		cGroup.children.splice(i, 1);
		onChange?.(cGroup);
	}

	console.log('RENDER: GROUP');

	return (
		<Box
			className={'group'}
			sx={{
				border: '1px solid #ccc',
				borderRadius: '3px',
				padding: '16px',
				margin: '8px 0',
				backgroundColor: '#fafafa',
			}}
		>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Box>
					<ToggleButton
						size="small"
						value="check"
						selected={group.negated}
						onChange={onNegated}
						sx={{ marginRight: '8px' }}
					>
						NOT
					</ToggleButton>
					<ToggleButtonGroup
						exclusive
						value={group.conjunction}
						onChange={(_e, conj) =>
							conj && onConjunctionChange(Conjunction.parse(conj))
						}
					>
						<ToggleButton size="small" value={'AND'}>
							AND
						</ToggleButton>
						<ToggleButton size="small" value={'OR'}>
							OR
						</ToggleButton>
					</ToggleButtonGroup>
				</Box>

				{!disabled && (
					<ButtonGroup>
						<Button onClick={onAddRule} size="small" startIcon={<AddIcon />}>
							Rule
						</Button>
						<Button onClick={onAddGroup} size="small" startIcon={<AddIcon />}>
							Group
						</Button>
						{!root && (
							<Button
								onClick={() => onDelete()}
								color="error"
								size="small"
								startIcon={<DeleteIcon />}
							>
								Delete
							</Button>
						)}
					</ButtonGroup>
				)}
			</Box>
			<Box>
				{group.children.map((child, i) => {
					switch (child.nodeType) {
						case 'GROUP':
							return (
								<Group
									key={JSON.stringify(child)}
									config={config}
									onChange={(grp) => onChildNodeChange(i, grp)}
									onDelete={() => onChildNodeDelete(i)}
									group={child}
									disabled={disabled}
								/>
							);
						case 'RULE':
							return (
								<Rule
									key={JSON.stringify(child)}
									config={config}
									onChange={(rule) => onChildNodeChange(i, rule)}
									onDelete={() => onChildNodeDelete(i)}
									rule={child}
									disabled={disabled}
								/>
							);
					}
				})}
			</Box>
		</Box>
	);
};
