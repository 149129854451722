type RowT = { code: string; description: string };

export const data: RowT[] = [
	{ code: 'campaigns_intro', description: 'Campaign view top section' },
	{ code: 'recruiment_step_1', description: 'Recruitment step 1' },
	{ code: 'recruiment_step_2', description: 'Recruitment step 2' },
	{ code: 'recruiment_step_3', description: 'Recruitment step 3' },
	{ code: 'recruitment_guidelines', description: 'Recruitment guidelines' },
	{ code: 'claim_description_1', description: 'Claim box description (left)' },
	{ code: 'claim_description_2', description: 'Claim box description (right)' },
	{ code: 'recover_password_intro', description: 'Recover password intro' },
	{ code: 'fulfill_email_change', description: 'Fulfill email change view' },
	{ code: 'cookie_text', description: 'Cookie policy' },
	{ code: 'terms_text', description: 'User terms of service' },
	{ code: 'privacy_text', description: 'Privacy policy' },
	{ code: 'about_us_text', description: 'About us' },
	{ code: 'footer_text', description: 'Footer text' },
	{ code: 'plugin_text', description: 'Autobäring plugin page text' },
	{ code: 'app_text', description: 'App page text' },
	{ code: 'howitworks_how', description: 'How it works - Intro' },
	{ code: 'howitworks_1', description: 'How it works - 1' },
	{ code: 'howitworks_2', description: 'How it works - 2' },
	{ code: 'howitworks_3', description: 'How it works - 3' },
	{ code: 'howitworks_4', description: 'How it works - 4' },
	{ code: 'home_top_section', description: 'Home top section' },
	{ code: 'home_how_it_works_intro', description: 'Home - How It Works Intro' },
	{ code: 'home_how_it_works_1', description: 'Home - How It Works 1' },
	{ code: 'home_how_it_works_2', description: 'Home - How It Works 2' },
	{ code: 'home_how_it_works_3', description: 'Home - How It Works 3' },
	{ code: 'home_app_intro', description: 'Home - App Intro' },
	{ code: 'home_plugin_intro', description: 'Home - Plugin Intro' },
	{ code: 'home_web_intro', description: 'Home - Web Intro' },
];
