import { config, ThemeName } from '@mybonus/public';

import {
	ImageDownloadAppStoreDark,
	ImageDownloadAppStoreWhite,
	ImageDownloadGooglePlayDark,
	ImageDownloadGooglePlayWhite,
} from '../../assets';
import { Anchor } from '../../primitives';
import { $LocalImage } from './DownloadAppButton.parts';

export type DownloadAppButtonProps = {
	vendor: 'ios' | 'android';
	size?: 'sm' | 'md';
	themeName?: ThemeName;
};

const vendors = {
	ios: {
		logo: {
			main: ImageDownloadAppStoreDark,
			invert: ImageDownloadAppStoreWhite,
		},
		url: config.app.ios.appStoreUrl,
		name: 'App Store',
	},
	android: {
		logo: {
			main: ImageDownloadGooglePlayDark,
			invert: ImageDownloadGooglePlayWhite,
		},
		url: config.app.android.playStoreUrl,
		name: 'Play Store',
	},
};

export function DownloadAppButton(props: DownloadAppButtonProps) {
	const { themeName = 'main', size = 'md' } = props;
	const { logo, url, name } = vendors[props.vendor];

	return (
		<Anchor to={{ url, newTab: true }}>
			<$LocalImage
				size={size}
				uri={logo[themeName]}
				alt={`Hämta från ${name}`}
			/>
		</Anchor>
	);
}
