import { styled } from '../../theme';
import { FavoriteList } from '../FavoriteList';
import { Popdown as _Popdown } from '../Popdown';

export const Popdown = styled(_Popdown)`
	min-width: 440px;
`;

export const $FavoriteList = styled(FavoriteList)`
	max-height: 500px;
	overflow-y: scroll;
`;
