import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import MuiLink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';

import {
	Page,
	ErrorPopup,
	Confirm,
	ActivateIcon,
	DeleteIcon,
} from '../../components';
import { usePageListView } from './PageListView.hooks';

export function PageListView() {
	const {
		list,
		deleteId,
		deletedIds,
		setDeleteId,
		setDeletedIds,
		remove,
		handleInactivate,
	} = usePageListView();

	return (
		<Page title="Pages">
			<ErrorPopup error={list.error} onRetry={list.load} onClose={list.reset} />

			<Confirm
				open={!!deleteId || remove.isLoading}
				onConfirm={() => {
					remove.mutate({ id: Number(deleteId) });
					setDeleteId(undefined);
					setDeletedIds([...deletedIds, Number(deleteId)]);
				}}
				onClose={() => setDeleteId(undefined)}
				title="Delete item?"
				message="Are you sure you want to delete this item?"
				isLoading={remove.isLoading}
			/>

			<Link to="/app/cms/page/create">
				<Button variant="contained">New Page</Button>
			</Link>
			{list.isLoading && <LinearProgress />}

			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Slug</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{list?.data?.map((row) => (
							<TableRow
								key={row.id}
								sx={{
									'&:last-child td, &:last-child th': { border: 0 },
									...(deletedIds.indexOf(row.id) !== -1 || !row.active
										? { opacity: 0.3 }
										: {}),
								}}
							>
								<TableCell component="th" scope="row">
									{row.id}
								</TableCell>
								<TableCell>
									<MuiLink component={Link} to={`/app/cms/page/${row.id}`}>
										{row.slug}
									</MuiLink>
								</TableCell>
								<TableCell>
									<ActivateIcon
										isActive={!!row.active}
										onClick={() => handleInactivate(row.id, !!row.active)}
									/>
									<DeleteIcon onClick={() => setDeleteId(row.id)} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Page>
	);
}
