import { PlatformAlias } from '@mybonus/public';
import { z } from 'zod';

export const Env = z.object({
	APP_PLATFORM_ALIAS: PlatformAlias,
	APP_VERSION: z.string(),
	APP_ENV: z.enum(['local', 'dev', 'master']),
	API_BASE_URL: z.string(),
	WEB_BASE_URL: z.string(),
	V1_BASE_URL: z.string(),
});
export type Env = z.infer<typeof Env>;

export default Env.parse({
	APP_VERSION: process.env.APP_VERSION,
	APP_PLATFORM_ALIAS: process.env.APP_PLATFORM_ALIAS,
	APP_ENV: process.env.APP_ENV,
	API_BASE_URL: process.env.API_BASE_URL,
	WEB_BASE_URL: process.env.WEB_BASE_URL,
	V1_BASE_URL: process.env.V1_BASE_URL,
});
