import {
	type ListStore as ListStoreType,
	imageUrlWithSize,
} from '@mybonus/public';

import { BestOfferLabel } from '../BestOfferLabel';
import {
	Item,
	LogoWrapper,
	Logo,
	Info,
	Name,
	Indicators,
} from './ListStore.parts';

export type ListStoreProps = {
	store: ListStoreType;
	onClick?: (id: number) => void;
};

export function ListStore(props: ListStoreProps & { className?: string }) {
	return (
		<Item
			className={props.className}
			onClick={() => props.onClick?.(props.store.id)}
		>
			{props.store.logoUrl && (
				<LogoWrapper>
					<Logo
						url={imageUrlWithSize(props.store.logoUrl, {
							w: '100',
							format: 'png',
						})}
						alt={props.store.name}
					/>
				</LogoWrapper>
			)}
			<Info>
				<Name>{props.store.name}</Name>
				{!!props.store.bestOffer && (
					<BestOfferLabel bestOffer={props.store.bestOffer} />
				)}
			</Info>
			<Indicators />
		</Item>
	);
}
