import type { GroupNode, Query } from '@mybonus/public';
import { noop } from 'lodash';
import React, { FC } from 'react';
import isEqual from 'react-fast-compare';

import { config, Config } from './config';
import { Group, createEmptyGroupNode } from './group';

export interface BuilderProps {
	config: Config;
	onChange?: (group: GroupNode) => void;
	query: Query;
	disabled?: boolean;
}

export function createEmptyQuery(): Query {
	return createEmptyGroupNode();
}

export const Builder: FC<BuilderProps> = React.memo(
	(props) => {
		return (
			<Group
				root
				config={config}
				onChange={(query) => props.onChange?.(query)}
				onDelete={noop}
				group={props.query}
				disabled={props.disabled}
			/>
		);
	},
	({ onChange: _, ...prev }, { onChange: __, ...next }) => isEqual(prev, next),
);
