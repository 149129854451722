import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AppShell, AdminRoute } from './components';
import * as V from './views';

export function Router() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/app" element={<AdminRoute component={AppShell} />}>
					<Route
						path="/app/marketing/audiences"
						element={<V.AudienceListView />}
					/>
					<Route
						path="/app/marketing/audiences/:id"
						element={<V.AudienceView />}
					/>
					<Route
						path="/app/marketing/audiences/create"
						element={<V.AudienceCreateView />}
					/>
					<Route
						path="/app/marketing/audiences/schedule"
						element={<V.AudienceSyncView />}
					/>
					<Route
						path="/app/marketing/cashback-shares/create"
						element={<V.CashbackSharesCreateView />}
					/>
					<Route
						path="/app/marketing/cashback-shares/list"
						element={<V.CashbackShareListView />}
					/>
					<Route
						path="/app/marketing/cashback-shares/list/:id"
						element={<V.CashbackShareView />}
					/>
					<Route
						path="/app/marketing/messages/notifications/list"
						element={<V.NotificationListView />}
					/>
					<Route
						path="/app/marketing/messages/notifications/list/:id"
						element={<V.NotificationView />}
					/>
					<Route
						path="/app/marketing/messages/notifications/create"
						element={<V.NotificationCreateView />}
					/>
					<Route
						path="/app/marketing/tools/universal-links"
						element={<V.UniversalLinkGeneratorView />}
					/>

					<Route path="/app/cms/faq/groups" element={<V.FaqGroupListView />} />
					<Route
						path="/app/cms/faq/groups/:id"
						element={<V.FaqGroupUpsertView />}
					/>
					<Route
						path="/app/cms/faq/groups/create"
						element={<V.FaqGroupUpsertView />}
					/>

					<Route path="/app/cms/faq/items" element={<V.FaqItemListView />} />
					<Route
						path="/app/cms/faq/items/:id"
						element={<V.FaqItemUpsertView />}
					/>
					<Route
						path="/app/cms/faq/items/create"
						element={<V.FaqItemUpsertView />}
					/>

					<Route
						path="/app/cms/content"
						element={<V.StaticContentListView />}
					/>
					<Route
						path="/app/cms/content/:code"
						element={<V.StaticContentUpdateView />}
					/>

					<Route path="/app/cms/page" element={<V.PageListView />} />
					<Route path="/app/cms/page/:id" element={<V.PageUpsertView />} />
					<Route path="/app/cms/page/create" element={<V.PageUpsertView />} />
				</Route>

				<Route path="/auth">
					<Route path="/auth/login" element={<V.AuthLoginView />} />
				</Route>

				<Route path="*" element={<p>404 not found</p>} />
			</Routes>
		</BrowserRouter>
	);
}
